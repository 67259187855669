import { Link, useLocation } from "react-router-dom";
import {
  LayoutDashboard,
  Cog,
  Globe,
  List,
  ShoppingBag,
  Share2,
  MonitorSmartphone,
  Sun,
  Moon,
  ChevronLeft,
  ChevronRight,
  Settings,
  X,
  Waypoints,
  ChevronDown,
} from "lucide-react";
import { useTheme } from "../../context/context";
import { useState, useEffect } from "react";

const Sidebar = ({ navControler }) => {
  const { theme, setTheme, isMinimized, setIsMinimized } = useTheme();
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState(null);
  const [transisition, setTransition] = useState(false);
  const [smallScreen, setSmallScreen] = useState(
    window?.innerWidth < 1024 ? true : false
  );

  const menuItems = [
    {
      icon: <LayoutDashboard className="min-w-5 h-5" />,
      label: "Dashboard",
      path: "/onboard/",
    },
    {
      icon: <Waypoints className="min-w-5 h-5" />,
      label: "Residential Proxies",
      path: "/onboard/generators",
      buttons: [
        {
          icon: <Globe className="min-w-5 h-5" />,
          label: "Premium Residential Proxy",
          path: "/onboard/rotating-proxy",
        },
        {
          icon: <Globe className="min-w-5 h-5" />,
          label: "Residential Proxy",
          path: "/onboard/budget-proxy",
        },
        {
          icon: <Globe className="min-w-5 h-5" />,
          label: "Static Residential Proxy",
          path: "/onboard/static-proxy",
        },
      ],
    },
    {
      icon: <Waypoints className="min-w-5 h-5" />,
      label: "Other Proxies",
      path: "/onboard/services",
      buttons: [
        {
          icon: <Globe className="min-w-5 h-5" />,
          label: "LTE Mobile Proxy",
          path: "/onboard/lte-proxy",
        },
        {
          icon: <MonitorSmartphone className="min-w-5 h-5" />,
          label: "IPv4 Datacenter Proxy",
          path: "/onboard/ipv4-proxy",
        },
        {
          icon: <MonitorSmartphone className="min-w-5 h-5" />,
          label: "IPv6 Datacenter Proxy",
          path: "/onboard/ipv6-proxy",
        },
      ],
    },
    {
      icon: <List className="min-w-5 h-5" />,
      label: "Generated Proxies",
      path: "/onboard/proxies",
    },
    {
      icon: <Share2 className="min-w-5 h-5" />,
      label: "Referral",
      path: "/onboard/referral",
    },
    {
      icon: <Settings className="min-w-5 h-5" />,
      label: "Settings",
      path: "/onboard/settings",
    },
  ];

  useEffect(() => {
    menuItems.map((item, index) => {
      if (item.path === location.pathname) {
        setOpenMenu(index);
      }
      if (item.buttons) {
        item.buttons.map((button) => {
          if (button.path === location.pathname) {
            setOpenMenu(index);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSmallScreen(window.innerWidth < 1024 ? true : false);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 1000);
  }, []);

  return (
    <div
      className={` fixed lg:sticky left-0 top-0 min-h-screen h-full p-4 border-r border-[#446e6d25] bg-slate-50 dark:bg-bgs dark:text-white z-[99] ${transisition ? "transition-all duration-300" : ""} overflow-hidden
      ${isMinimized ? "w-[320px] lg:w-[72px]" : "w-[320px] lg:w-[380px]"} ${
        isMinimized && smallScreen ? "translate-x-[-100%]" : "translate-x-0"
      }`}
    >
      {/* Logo & Minimize Button */}
      <div className="flex items-center justify-between mb-9 px-2 pt-2">
        <Link to={"/"}>
          <span className="font-semibold text-2xl">
            <span className="font-india text-primary">Q</span>
            {(!isMinimized || smallScreen) && "socks"}
          </span>
        </Link>
        {(!isMinimized || smallScreen) && (
          <button
            onClick={() => setIsMinimized(!isMinimized || smallScreen)}
            className="p-2 rounded-full hover:bg-primary/15 transition"
          >
            {isMinimized ? (
              <ChevronRight className="min-w-5 h-5" />
            ) : smallScreen ? (
              <X className="min-w-5 h-5" />
            ) : (
              <ChevronLeft className="min-w-5 h-5" />
            )}
          </button>
        )}
      </div>

      {/* Navigation */}
      <nav className="space-y-1">
        {isMinimized && !smallScreen && (
          <button
            onClick={() => setIsMinimized(!isMinimized || smallScreen)}
            className="p-2 rounded-full hover:bg-primary/15 transition"
          >
            {isMinimized ? (
              <ChevronRight className="min-w-5 h-5" />
            ) : (
              <ChevronLeft className="min-w-5 h-5" />
            )}
          </button>
        )}
        {menuItems.map((item, index) => (
          <div key={index}>
            {/* Main Menu Item */}
            <Link
              to={!item.buttons ? item.path : "#"}
              className={`flex flex-nowrap items-center  gap-3 px-2 py-2 rounded-lg hover:bg-primary/15 transition-colors relative 
                ${
                  location.pathname === item.path ||
                  item?.buttons?.some(
                    (button) => location.pathname === button.path
                  )
                    ? "bg-primary/15"
                    : ""
                }`}
              onClick={() => {
                if (item.buttons) {
                  setOpenMenu(openMenu === index ? null : index);
                }
                if(item.buttons && isMinimized && !smallScreen) {
                  setOpenMenu(openMenu === index ? null : index);
                  setIsMinimized(false);
                }
                if (smallScreen && !item.buttons) {
                  setIsMinimized(true);
                }
              }}
            >
              
              {item.icon}
              {(!isMinimized || smallScreen) && <span className="text-nowrap">{item.label}</span>}
              
              {item.buttons && !isMinimized && <span className="absolute right-2 top-1/2 -translate-y-1/2 text-primary"><ChevronDown className={`${openMenu === index ? "rotate-[-180deg]" : ""} transition-all duration-700`} /></span>}
            </Link>

            {/* Dropdown Buttons */}
            {item.buttons &&
              openMenu === index &&
              (!isMinimized || smallScreen) && (
                <div className="ml-4 mt-2 space-y-1 text-nowrap">
                  {item.buttons.map((button, subIndex) => (
                    <Link
                      key={subIndex}
                      to={button.path}
                      className={`flex items-center justify-start gap-3 px-2 py-2 rounded-lg hover:bg-primary/15 transition-colors
                      ${
                        location.pathname === button.path ? "bg-primary/15" : ""
                      }`}
                      onClick={() => {
                        if (smallScreen) {
                          setIsMinimized(true);
                        }
                      }}
                    >
                      {button.icon}
                      <span>{button.label}</span>
                    </Link>
                  ))}
                </div>
              )}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
