import React from "react";

export default function Comp({ data, onClick, loading }) {
  // Skeleton loader component for plans
  const PlanSkeleton = () => {
    return (
      <div className="p-4 lg:p-6 pt-0  flex flex-wrap gap-4">
        {[...Array(3)].map((_, i) => (
          <div
            className="flex w-[200px] bg-[#F3F4F6] border border-primary/20 dark:bg-bgp rounded-md overflow-hidden flex-col gap-[30px] p-4 justify-between"
            key={i}
          >
            {/* Plan details skeleton */}
            <div className="flex flex-col gap-5 items-start">
              <div className="h-6 w-16 bg-gray-200 dark:bg-gray-700 rounded-[6px] animate-pulse"></div>
              <div className="flex flex-col gap-[10px] w-full">
                <div className="h-7 bg-gray-200 dark:bg-gray-700 w-3/4 rounded animate-pulse"></div>
                <div className="h-16 bg-gray-200 dark:bg-gray-700 w-full rounded animate-pulse"></div>
              </div>
            </div>

            {/* Price & Buy Button skeleton */}
            <div className="flex flex-col gap-[30px]">
              <div className="flex items-end justify-end w-full">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 w-1/3 rounded animate-pulse"></div>
              </div>
              <div className="h-8 bg-gray-200 dark:bg-gray-700 w-full rounded animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  // grid grid-row-1 md:grid-cols-2 xl:grid-cols-3 
  return (
    <div className="w-full">
      {loading ? (
        <PlanSkeleton />
      ) : data?.plans ? (
        <div className="p-4 lg:p-6 pt-0 gap-4 flex flex-wrap">
          {data?.plans?.map((item, i) => (
            <div
              className="flex w-[200px] bg-[#F3F4F6] border border-primary/20 dark:bg-bgp rounded-md overflow-hidden dark:bg-dark-300 flex-col gap-[30px] p-4 justify-between"
              key={i}
            >
              {/* Plan details */}
              <div className="flex flex-col gap-5 items-start">
                <div className="py-1  px-[10px] rounded-[6px] text-white text-xs lg:text-sm leading-[14px] font-semibold bg-primary">
                  {item?.tag}
                </div>
                <div className="w-full flex flex-col gap-[10px] text-gray-700 dark:text-white">
                  <p className="text-xl leading-5 font-semibold">
                    {item?.name}
                  </p>
                  <p className="w-full text-sm leading-[19.6px] whitespace-pre-line dark:text-gray-400  break-all">
                    {item?.note} 
                  </p>
                </div>
              </div>

              {/* Price & Buy Button */}
              <div className="flex flex-col gap-[30px]">
                <div className="flex items-end text-gray-700 dark:text-white">
                  {[
                    "Premium Residential Proxies",
                    "Budget Residential Proxies",
                  ].includes(data.name) && (
                    <p className=" dark:text-dark-800 text-end text-[16px] leading-none font-medium text-nowrap">
                      {item?.amount} GB for <span className="invisible">.</span>
                    </p>
                  )}
                  <div className="flex items-end justify-end w-full">
                    <p className=" dark:text-dark-800 text-end text-[26px] leading-[28px] font-medium font-sans ">
                      ${item?.price}
                    </p>
                    <p className="font-semibold pl-1 text-end text-grey text-base leading-[18px] dark:text-gray-400">
                      {[
                        "Premium Residential Proxies",
                        "Budget Residential Proxies",
                      ].includes(data.name)
                        ? ""
                        : "/ PCS"}
                    </p>
                  </div>
                </div>
                <button
                  className="text-center rounded-md px-[18px] text-sm lg:text-base font-semibold disabled:cursor-not-allowed dark:bg-dark-600 py-1 dark:text-white hover:opacity-80 disabled:opacity-80 w-full bg-primary text-white"
                  onClick={() => {
                    if (onClick) {
                      onClick(item);
                    }
                  }}
                >
                  Buy now
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="min-h-[300px] h-full pb-10 w-full flex items-center justify-center text-center text-gray-700 dark:text-gray-400">
          No plans available
        </div>
      )}
    </div>
  );
}
