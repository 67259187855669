import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, CircleCheckBig } from "lucide-react";
import { useTheme } from "../../context/context";
import { unknown } from "zod";

export default function Pricedata() {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { plans } = useTheme();

  // Define pricing plans data
  const pricingData = [
    {
      size: "1000GB",
      price: "750",
      validityPeriod: "30 days",
      regions: "US JP CA ID AU VN UK FR DE",
      countrySelection: "City level",
      protocols: true,
      ipDuration: "1~240 minutes",
      availability: "99.7%",
    },
    {
      size: "5GB",
      price: "15",
      validityPeriod: "30 days",
      regions: "US JP CA ID AU VN UK FR DE",
      countrySelection: "City level",
      protocols: true,
      ipDuration: "1~240 minutes",
      availability: "99.7%",
    },
    {
      size: "10GB",
      price: "28",
      validityPeriod: "30 days",
      regions: "US JP CA ID AU VN UK FR DE",
      countrySelection: "City level",
      protocols: true,
      ipDuration: "1~240 minutes",
      availability: "99.7%",
    },
    {
      size: "40GB",
      price: "96",
      validityPeriod: "30 days",
      regions: "US JP CA ID AU VN UK FR DE",
      countrySelection: "City level",
      protocols: true,
      ipDuration: "1~240 minutes",
      availability: "99.7%",
    },
  ];

  // Features list for the first column
  const features = [
    "Popular regions",
    "Country selection",
    "Http(S) / Socks5",
    "Plan Name",
    "IP Availability",
  ];

  // Proxy type options
  const proxyTypes = [
    {
      name: "Premium Residential Proxies",
      value: "Premium Residential Proxies",
    },
    { name: "Residential Proxies", value: "Budget Residential Proxies" },
    { name: "Static Residential Proxies", value: "Static Residential Proxies" },
    { name: "LTE Mobile Proxies", value: "LTE Mobile Proxies" },
    { name: "Datacenter Proxies", value: "Datacenter Proxies" },
    { name: "Datacenter IPv6 Proxies", value: "Datacenter IPv6 Proxies" },
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Toggle between plans
  const handlePlanToggle = (index) => {
    setSelectedPlan(index);
    setIsDropdownOpen(false);
  };

  const getPlan = () => {
    const currentPlan = plans?.find(
      (plan) => plan.name === proxyTypes[selectedPlan].value
    );
    return currentPlan ? currentPlan : { plans: [] };
  };

  const getValidityPeriod = (plan) => {
    const currentPlan = proxyTypes[selectedPlan].value;

    if (
      ["Premium Residential Proxies", "Budget Residential Proxies"].includes(
        currentPlan
      )
    ) {
      return `${plan?.amount} GB`;
    } else if (
      [
        "Static Residential Proxies",
        "Datacenter Proxies",
        "Datacenter IPv6 Proxies",
      ].includes(currentPlan)
    ) {
    } else if (["LTE Mobile Proxies"].includes(currentPlan)) {
      return plan.validityPeriod;
    }

    return "Unknown";
  };

  return (
    <div className="flex flex-col mt-8 items-center justify-center px-4 sm:px-6 md:px-8 lg:px-16 max-w-[1552px] mx-auto mb-[100px]" id="pricing">
      <div className="container flex flex-col items-center justify-center w-full py-6 m-4 text-center px-0">
        <h2
          className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white"
          style={{
            display: "inline-block",
            verticalAlign: "top",
            textDecoration: "inherit",
            textWrap: "balance",
          }}
        >
          Pricing
        </h2>
      </div>
      {/* Plan Selection - Mobile Dropdown */}
      <div className="w-full flex items-center justify-center mb-8 lg:mb-10 md:hidden">
        <div ref={dropdownRef} className="relative w-full max-w-[320px]">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center justify-between w-full p-3 text-left bg-primary-light border-2 border-primary rounded-xl font-bold text-primary-foreground dark:text-white"
          >
            <span className="truncate mr-2">
              {proxyTypes[selectedPlan].name}
            </span>
            <ChevronDown
              className={`transition-transform flex-shrink-0 ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
              size={20}
            />
          </button>

          {isDropdownOpen && (
            <div className="absolute z-10 w-full mt-2 bg-primary-light border-2 border-primary rounded-xl shadow-lg max-h-60 overflow-y-auto bg-white dark:bg-bgs custom-scrollbar">
              {proxyTypes.map((type, index) => (
                <button
                  key={index}
                  onClick={() => handlePlanToggle(index)}
                  className={`block w-full text-left p-3 hover:text-background transition-colors ${
                    selectedPlan === index
                      ? "bg-primary text-background"
                      : "text-primary-foreground hover:bg-primary/15"
                  } ${index === 0 ? "rounded-t-lg" : ""} ${
                    index === proxyTypes.length - 1 ? "rounded-b-lg" : ""
                  }`}
                >
                  <span className="block truncate dark:text-white">
                    {type.name}
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Plan Selection - Tablet/Desktop Tabs */}
      <div className="hidden md:flex w-full items-center justify-center mb-8 lg:mb-10 overflow-x-auto scrollbar-hide ">
        <div className="inline-flex flex-wrap gap-2 p-1 bg-primary-light rounded-full justify-center">
          {proxyTypes.map((type, index) => (
            <button
              key={index}
              onClick={() => handlePlanToggle(index)}
              className={`px-3 py-2 md:px-4 whitespace-nowrap text-xs sm:text-sm font-medium rounded-full transition-all ${
                selectedPlan === index
                  ? "bg-primary text-background shadow-sm text-white"
                  : "text-primary-foreground hover:bg-primary/15 hover:hover:bg-primary/15  dark:text-gray-400 dark:bg-bgp"
              }`}
            >
              {type.name}
            </button>
          ))}
        </div>
      </div>

      {/* Pricing Grid - Mobile View */}
      <div className="block md:hidden w-full">
        <div className="space-y-6 sm:space-y-8">
          {/* Use API data when available, fallback to static data */}
          {(getPlan()?.plans?.length > 0 ? getPlan()?.plans : pricingData).map(
            (plan, index) => (
              <div
                key={index}
                className="bg-white shadow-md border-none leading-5 bg-primary-light rounded-3xl hover:text-background transition-colors duration-300 ease-in-out dark:bg-bgp  cursor-pointer"
              >
                {/* Card Header */}
                <div className="p-4 flex flex-col items-center">
                  {/* Size Badge */}
                  <div className="flex items-center justify-center px-4 mt-1">
                    <span className="text-xs sm:text-sm min-w-[80px] sm:min-w-[100px] pointer-events-none py-0.5 text-center font-bold bg-primary text-white rounded-full transition-colors group-hover:bg-white group-hover:text-primary">
                      {plan?.tag || plan?.size || ""}
                    </span>
                  </div>

                  {/* Price */}
                  <div className="my-3 sm:my-4 pointer-events-none flex font-sans items-center justify-center gap-1 sm:gap-2 text-primary-foreground group-hover:text-white">
                    <h3 className="mb-0 tracking-tighter text-2xl sm:text-4xl dark:text-white">
                      $
                    </h3>
                    <h3 className="mb-0 tracking-tighter text-4xl sm:text-5xl font-semibold dark:text-white">
                      {plan?.price || "0"}
                    </h3>
                  </div>

                  {/* Validity Period */}
                  <div className="flex flex-col gap-2 sm:gap-4 px-4 py-2 text-gray-400 pointer-events-none">
                    <div className="flex items-center gap-2">
                      <CircleCheckBig
                        className="text-primary group-hover:text-white"
                        size={18}
                      />
                      <div className="flex justify-between w-full">
                        <span className="text-xs">Validity period</span>
                        <span className="text-xs font-bold">
                          {getValidityPeriod(plan)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Features */}
                <div className="grid grid-cols-1 gap-2 p-4">
                  <div className="flex justify-between items-center">
                    <span className="text-xs sm:text-sm font-bold text-gray-400">
                      Popular regions
                    </span>
                    <span className="text-xs sm:text-sm text-primary-foreground font-bold group-hover:text-white truncate ml-4 text-right dark:text-gray-300">
                      {plan?.regions || "US, EU, APAC"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-xs sm:text-sm font-bold text-gray-400">
                      Country selection
                    </span>
                    <span className="text-xs sm:text-sm text-primary-foreground font-bold group-hover:text-white dark:text-gray-300">
                      {plan?.countrySelection || "Available"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-xs sm:text-sm font-bold text-gray-400">
                      Http(S) / Socks5
                    </span>
                    <span className="text-xs sm:text-sm group-hover:text-white">
                      <CircleCheckBig
                        className="text-primary group-hover:text-white"
                        size={18}
                      />
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-xs sm:text-sm font-bold text-gray-400">
                      IP Duration
                    </span>
                    <span className="text-xs sm:text-sm text-primary-foreground font-bold group-hover:text-white dark:text-gray-300">
                      {plan?.ipDuration || "Rotating"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-xs sm:text-sm font-bold text-gray-400">
                      IP Availability
                    </span>
                    <span className="text-xs sm:text-sm text-primary-foreground font-bold group-hover:text-white dark:text-gray-300">
                      {plan?.availability || "99.7%"}
                    </span>
                  </div>
                </div>

                {/* Button */}
                <div className="p-4 flex justify-center">
                  <button className="inline-flex items-center justify-center gap-1 sm:gap-2 whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 h-9 sm:h-10 px-4 sm:px-8 text-sm sm:text-lg rounded-full bg-transparent font-bold text-primary border-primary border cursor-pointer group-hover:bg-white group-hover:text-primary w-full">
                    Buy Now
                  </button>
                </div>
              </div>
            )
          )}

          {/* Show message when no plans available */}
          {getPlan()?.plans?.length === 0 && pricingData.length === 0 && (
            <div className="text-center p-8 bg-primary-light rounded-3xl dark:bg-bgp">
              <p className="text-lg text-primary-foreground dark:text-gray-300">
                No plans available for this proxy type.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Pricing Grid - Tablet/Desktop View (Table Layout) */}
      <div className="hidden md:grid grid-cols-1 md:grid-cols-[1fr_repeat(4,minmax(0,1fr))] gap-x-2 lg:gap-x-3 gap-y-6 lg:gap-y-9 w-full max-w-full overflow-x-auto pt-6 items-center justify-center min-h-[468px]">
        {/* Features Column */}
        <div className="pointer-events-none self-end p-0 leading-5">
          <div className="flex flex-col justify-between">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex justify-start md:justify-center px-2 md:px-4 lg:px-6 py-4 w-full"
              >
                <span className="text-xs sm:text-sm font-bold text-center text-gray-400 whitespace-normal md:whitespace-nowrap ">
                  {feature}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Plan Cards */}
        {getPlan()?.plans?.map((plan, index) => (
          <div
            key={index}
            className="border-2 dark:border-bgs leading-5 self-end max-w-full bg-primary-light flex-1 rounded-3xl hover:text-background antialiased backface-visibility-hidden transform relative bg-white p-4 cursor-pointer transition-all duration-300 ease-in-out dark:bg-bgp dark:hover:border-primary hover:border-primary hover:shadow-lg hover:-translate-y-2 "
          >
            {/* Card Content */}
            <div className="flex flex-col gap-2 h-full p-2 md:p-3 justify-between py-4">
              {/* Size Badge */}
              <div className="flex items-center justify-center px-2 md:px-4 mt-1">
                <span className="text-xs md:text-sm min-w-[80px] md:min-w-[100px] pointer-events-none py-0.5 text-center text-white font-bold bg-primary text-background rounded-full transition-colors group-hover:bg-white group-hover:text-primary">
                  {plan?.tag}
                </span>
              </div>
              {/* Price */}
              <div className="my-2 md:my-4 pointer-events-none flex font-sans items-center justify-center gap-1 md:gap-2 text-primary-foreground group-hover:text-background">
                <h3 className="mb-0 tracking-tighter text-2xl md:text-4xl group-hover:text-white dark:text-white font-sans price">
                  $
                </h3>
                <h3 className="mb-0 tracking-tighter text-3xl md:text-5xl font-semibold group-hover:text-white dark:text-white font-sans price">
                  {plan.price}
                </h3>
              </div>

              {/* Validity Period */}
              <div className="flex flex-col gap-2 md:gap-4 px-2 md:px-4 py-1 md:py-2 text-gray-400 group-hover:text-gray-600 pointer-events-none">
                <div className="flex items-center gap-1 md:gap-2">
                  <CircleCheckBig
                    className="text-primary group-hover:text-gray-700 "
                    size={16}
                  />
                  <div className="flex justify-between w-full">
                    <span className="text-xs">Validity period</span>
                    <span className="text-xs font-bold">
                      {getValidityPeriod(plan)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Button */}
              <button className="inline-flex items-center justify-center gap-1 md:gap-2 whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 h-8 md:h-10 px-4 md:px-8 text-sm md:text-lg rounded-full bg-transparent font-bold text-primary border-primary border cursor-pointer group-hover:bg-background group-hover:text-primary-foreground group-hover:text-primary group-hover:border-white group-hover:bg-white">
                Buy Now
              </button>
            </div>

            {/* Features List */}
          {/* Features List */}
<div className="flex flex-col h-full justify-between pointer-events-none">
  {/* Regions */}
  <div className="flex justify-center py-2 w-full px-1">
    <span className="text-xs md:text-sm text-primary-foreground font-bold group-hover:text-white text-center break-words dark:text-gray-300">
      {plan?.regions || "US, EU, APAC"}
    </span>
  </div>

  {/* Country Selection */}
  <div className="flex justify-center py-2 w-full">
    <span className="text-xs md:text-sm text-primary-foreground group-hover:text-white dark:text-gray-300">
      {plan?.countrySelection || "Available"}
    </span>
  </div>

  {/* Protocols */}
  <div className="flex justify-center py-2 w-full">
    <span className="flex justify-center items-center">
      {plan?.protocols !== false && (
        <CircleCheckBig
          className="text-primary group-hover:text-white"
          size={18}
        />
      )}
    </span>
  </div>

  {/* Plan Name */}
  <div className="flex justify-center py-2 w-full px-1">
    <span className="text-xs md:text-sm text-primary-foreground font-bold group-hover:text-white text-center break-words dark:text-gray-300">
      {plan?.name || `${plan?.tag || ""} Package`}
    </span>
  </div>

  {/* IP Availability */}
  <div className="flex justify-center py-2 w-full">
    <span className="text-xs md:text-sm text-primary-foreground font-bold group-hover:text-white dark:text-gray-300">
      {plan?.availability || "99.7%"}
    </span>
  </div>
</div>
          </div>
        ))}
      </div>
    </div>
  );
}
