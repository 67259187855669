import React from 'react';
import { Link } from 'react-router-dom';
import { User, Phone, Mail, FileText, Upload, Trash, Save } from "lucide-react"; // Import Lucide icons

const FeaturesSection = () => {
  return (
    <div id="features" className='max-w-screen-2xl overflow-hidden px-5 lg:px-20 flex flex-wrap items-center mx-auto justify-center'>
      <div className="container flex flex-col items-center justify-center w-full py-6 m-4 text-center px-0">
        <h2 className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white" 
            style={{display: 'inline-block', verticalAlign: 'top', textDecoration: 'inherit', textWrap: 'balance'}}>
          Proxies for every need
        </h2>
      </div>
      
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
        {/* Feature Card 1 */}
        <div className="my-5 dark:bg-[#161616]  border shadow-md dark:border-none dark:shadow-none  first:xl:col-auto flex items-center flex-col justify-between rounded-2xl p-6 bg-white">
          <div>
            <div className="flex items-center justify-center text-primary dark:text-primary">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" className="w-14 h-14" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <polygon fill="#CFD8DC" points="40,9 40,7 31,7 31,12 24,12 15,12 15,23 8,23 8,25 15,25 15,36 24,36 31,36 31,41 40,41 40,39 33,39 33,31 40,31 40,29 31,29 31,34 24,34 17,34 17,14 24,14 31,14 31,19 40,19 40,17 33,17 33,9"></polygon>
                <rect x="4" y="20" fill="#00BCD4" width="8" height="8"></rect>
                <g fill="#3F51B5">
                  <rect x="36" y="14" width="8" height="8"></rect>
                  <rect x="36" y="4" width="8" height="8"></rect>
                  <rect x="20" y="9" width="8" height="8"></rect>
                  <rect x="20" y="31" width="8" height="8"></rect>
                  <rect x="36" y="36" width="8" height="8"></rect>
                  <rect x="36" y="26" width="8" height="8"></rect>
                </g>
              </svg>
            </div>
            <h3 className="pt-2 my-4 text-2xl font-semibold text-black text-center dark:text-white text-opacity-90">
              Overcome Restrictions
            </h3>
            <p className="text-sm leading-7 dark:text-gray-300">
              Bypass CAPTCHAs, geo-blocks, and IP bans effortlessly with our extensive network of over 80 million proxies spanning 200+ locations worldwide, including specific cities and US states.
            </p>
          </div>
          
          <Link className="p-2 text-primary content-end flex items-center text-center font-semibold text-base dark:text-gray-400 text-opacity-70" to="/login">
            <div className="gap-2 cursor-pointer font-semibold flex text-primary transition-all items-center justify-center w-full text-sm hover:text-primary-foreground">
              MORE
              <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
              </svg>
            </div>
          </Link>
        </div>

        {/* Feature Card 2 */}
        <div className="my-5 dark:bg-[#161616]  border shadow-md dark:border-none dark:shadow-none  first:xl:col-auto flex items-center flex-col justify-between rounded-2xl p-6 bg-white">
          <div>
            <div className="flex items-center justify-center text-primary dark:text-primary">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" className="w-14 h-14" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <g fill="#1976D2">
                  <path d="M17.5,27c-1.1,1.2-2.7,2-4.5,2h-3c-3.3,0-6-2.7-6-6s2.7-6,6-6h3c1.8,0,3.4,0.8,4.5,2h4.7 c-1.5-3.5-5.1-6-9.2-6h-3C4.5,13,0,17.5,0,23s4.5,10,10,10h3c4.1,0,7.6-2.5,9.2-6H17.5z"></path>
                  <path d="M38,13h-3c-4.1,0-7.6,2.5-9.2,6h4.7c1.1-1.2,2.7-2,4.5-2h3c3.3,0,6,2.7,6,6s-2.7,6-6,6h-3 c-1.8,0-3.4-0.8-4.5-2h-4.7c1.5,3.5,5.1,6,9.2,6h3c5.5,0,10-4.5,10-10S43.5,13,38,13z"></path>
                </g>
                <g fill="#00BCD4">
                  <polygon points="19.5,4 16,6 22.1,14.1 23.4,13.3"></polygon>
                  <polygon points="28.5,4 32,6 25.9,14.1 24.6,13.3"></polygon>
                  <polygon points="28.5,44 32,42 25.9,33.9 24.6,34.7"></polygon>
                  <polygon points="19.5,44 16,42 22.1,33.9 23.4,34.7"></polygon>
                </g>
              </svg>
            </div>
            <h3 className="pt-2 my-4 text-2xl font-semibold text-black text-center dark:text-white text-opacity-90">
              Expand Your Business
            </h3>
            <p className="text-sm leading-7 dark:text-gray-300">
              Effortlessly scale operations, whether it's scraping data from multiple sources simultaneously or managing numerous social media and eCommerce accounts.
            </p>
          </div>
          
          <Link className="p-2 text-primary content-end flex items-center text-center font-semibold text-base dark:text-gray-400 text-opacity-70" to="/login">
            <div className="gap-2 cursor-pointer font-semibold flex text-primary transition-all items-center justify-center w-full text-sm hover:text-primary-foreground">
              MORE
              <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
              </svg>
            </div>
          </Link>
        </div>

        {/* Feature Card 3 */}
        <div className="my-5 dark:bg-[#161616]  border shadow-md dark:border-none dark:shadow-none  first:xl:col-auto flex items-center flex-col justify-between rounded-2xl p-6 bg-white">
          <div>
            <div className="flex items-center justify-center text-primary dark:text-primary">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" className="w-14 h-14 text-black" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <g fill="#00BCD4">
                  <rect x="37" y="18" width="6" height="24"></rect>
                  <rect x="29" y="26" width="6" height="16"></rect>
                  <rect x="21" y="22" width="6" height="20"></rect>
                  <rect x="13" y="32" width="6" height="10"></rect>
                  <rect x="5" y="28" width="6" height="14"></rect>
                </g>
                <g fill="#3F51B5">
                  <circle cx="8" cy="16" r="3"></circle>
                  <circle cx="16" cy="18" r="3"></circle>
                  <circle cx="24" cy="11" r="3"></circle>
                  <circle cx="32" cy="13" r="3"></circle>
                  <circle cx="40" cy="9" r="3"></circle>
                  <polygon points="39.1,7.2 31.8,10.9 23.5,8.8 15.5,15.8 8.5,14.1 7.5,17.9 16.5,20.2 24.5,13.2 32.2,15.1 40.9,10.8"></polygon>
                </g>
              </svg>
            </div>
            <h3 className="pt-2 my-4 text-2xl font-semibold text-black text-center dark:text-white text-opacity-90">
              Maintain Flexible
            </h3>
            <p className="text-sm leading-7 dark:text-gray-300">
              Explore our solutions risk-free with no-commitment options and complimentary trials for peace of mind.
            </p>
          </div>
          
          <Link className="p-2 text-primary content-end flex items-center text-center font-semibold text-base dark:text-gray-400 text-opacity-70" to="/login">
            <div className="gap-2 cursor-pointer font-semibold flex text-primary transition-all items-center justify-center w-full text-sm hover:text-primary-foreground">
              MORE
              <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;