import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeroSection from "../components/homepage/hero";
import Header from "../components/homepage/header";
import FeaturesSection from "../components/homepage/features";
import PartnersSection from "../components/homepage/partners";
import Details from "../components/homepage/details";
import GlobalCoverage from "../components/homepage/coverage";
import Footer from "../components/homepage/footer";
import Faq from "../components/homepage/faq";
import Plans from "../components/homepage/pricedata";
import UserGuide from "../components/homepage/userGuide";

const HomePage = () => {
  useEffect(() => {
    if (window.location.hash === "#faq") {
      const faqSection = document.getElementById("faq");
      if (faqSection) {
        faqSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div className="">
      <Header />
      <HeroSection />
      <PartnersSection />
      {/* <IntegrationSection /> */}
      <FeaturesSection />
      <Details />
      <GlobalCoverage />
      <Plans />
      <UserGuide />
      <Faq />
      <Footer />
    </div>
  );
};

// const FeatureList = () => {
//   const features = [
//     "80M+ Original Residential IPs",
//     "99.96% Uptime",
//     "State, city-level targeting",
//     "200+ locations",
//     "HTTP(S) & SOCKS5"
//   ];

//   return (
//     <div className="px-5 lg:px-20 flex flex-wrap gap-5 items-center mx-auto justify-center">
//       {features.map((feature, index) => (
//         <div key={index} className="flex items-center justify-center gap-2 text-white text-lg">
//           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-check-big w-4 h-4 text-primary group-hover:text-background">
//             <path d="M21.801 10A10 10 0 1 1 17 3.335"></path>
//             <path d="m9 11 3 3L22 4"></path>
//           </svg>
//           <span className="rt-Text rt-r-size-3">{feature}</span>
//         </div>
//       ))}
//     </div>
//   );
// };

const IntegrationSection = () => {
  const partners = [
    { name: "BitBrowser", image: "bit-browser.webp" },
    { name: "GoLogin", image: "gologin.webp" },
    { name: "MuLogin", image: "Mulogin.webp" },
    { name: "Incogniton", image: "incogniton.svg" },
    { name: "Undetectable", image: "Undetectable.svg" },
    { name: "Capsolver", image: "Capsolver.webp" },
    {
      name: "Xlogin",
      image: "Xlogin.png",
      className: "max-w-96",
      noText: true,
    },
  ];

  return (
    <section id="integration" className="rt-Section rt-r-size-1 relative">
      <div className="rt-Container rt-r-size-4 rt-r-py-5 xs:rt-r-py-6 sm:rt-r-py-7 md:rt-r-py-8 rt-r-mx-5 xs:rt-r-mx-6 sm:rt-r-mx-7 md:rt-r-mx-8 max-w-layout m-auto">
        <div className="rt-ContainerInner">
          <div className="container flex flex-col items-center justify-center w-full py-6 m-4 text-center px-0">
            <h2 className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white">
              Partners of MoMoProxy
            </h2>
          </div>

          <div className="overflow-x-hidden relative flex items-center justify-center">
            <div className="md:py-12 md:animate-marquee grid whitespace-nowrap md:flex justify-center">
              {partners.map((partner, index) => (
                <PartnerLogo key={index} partner={partner} />
              ))}
            </div>

            <div className="absolute hidden top-12 md:animate-marquee1 whitespace-nowrap md:flex justify-center">
              {partners.map((partner, index) => (
                <PartnerLogo key={index} partner={partner} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PartnerLogo = ({ partner }) => {
  const getImageSrc = (img) => {
    if (img.startsWith("/")) return img;
    return `/_next/image?url=%2F${img}&w=128&q=75`;
  };

  return (
    <div className="my-5 mr-5 lg:m-5 flex items-center">
      <div className="flex mr-1 items-center justify-center text-indigo-600 min-w-14 min-h-14 dark:text-indigo-300">
        <img
          alt={partner.name}
          loading="lazy"
          width={partner.className ? "178" : "60"}
          height={partner.className ? "56" : "60"}
          decoding="async"
          className={partner.className || ""}
          style={{ color: "transparent" }}
          src={getImageSrc(partner.image)}
        />
      </div>
      {!partner.noText && (
        <div className="text-2xl font-bold">{partner.name}</div>
      )}
    </div>
  );
};

export default HomePage;
