import React, { useEffect, useState } from "react";
import { ChevronDown, Copy, Download } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";
import Plans from "../components/shared/ui/planscomponent";
import ProxyHistory from "../components/shared/ui/historytable";
import axios from "axios";
import Slidebutton from "../components/shared/ui/slidebutton";
import * as countries from "i18n-iso-countries";
import toast from "react-hot-toast";
import { useTheme } from "../context/context";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function Page() {
  const [plans, setPlans] = useState([]);
  const [selectedcountry, setselectedcountry] = useState();
  const [selectedcity, setselectedcity] = useState({});
  const [valueList, setValueList] = useState({});
  const [port, setport] = useState("Http/s");
  const [countrylist, setcountrylist] = useState([]);
  const [citylist, setcitylist] = useState();
  const [selectedIsp, setselectedIsp] = useState({});
  const [availableIsp, setavailableIsp] = useState();
  const [tempdata, settempdata] = useState([]);
  const [History, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plansLoading, setPlansLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const { plans: existingPlans, setUser, proxyHistory } = useTheme();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchCountries = async () => {
      setLoading(true);
      if (selectedcountry) {
        setCityLoading(true);
      }
      try {
        const response = await axios.get("/api/user/lte_credentials", {
          params: selectedcountry && { country: selectedcountry },
          withCredentials: true,
          signal: signal,
        });
        if (!signal.aborted) {
          if (!selectedcountry) {
            const countrylist = response?.data?.data?.map((country) => {
              const countryCode = country.toUpperCase();
              return {
                name: countries.getName(countryCode, "en") || country,
                code: country,
              };
            });
            setcountrylist(countrylist);
          } else {
            settempdata(response?.data?.data);
            const allCities = response?.data?.data
              ?.filter((item) => item.city)
              ?.map((item) => item.city);
            const uniqueCities = [...new Set(allCities)];
            const formattedCities = uniqueCities.map((cityName) => ({
              name: cityName,
            }));
            setcitylist(formattedCities);
          }
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.log(error);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
          setCityLoading(false);
        }
      }
    };
    fetchCountries();
    return () => {
      abortController.abort();
    };
  }, [selectedcountry]);

  useEffect(() => {
    if (selectedcity?.name) {
      const ispList = tempdata
        ?.filter(
          (isp) =>
            (port === "Http/s"
              ? isp?.type === "HTTPS / WIFI"
              : isp?.type !== "HTTPS / WIFI") &&
            isp?.city === selectedcity?.name
        )
        .map((isp) => {
          return {
            ...isp,
            name: `Ip-${isp?.ip}:Type-${isp?.type}:Isp-${isp?.isp}`,
          };
        });
      console.log(ispList);
      setavailableIsp(ispList);
      setValueList((prev) => ({ ...prev, isp: null }));
    }
  }, [citylist, selectedcity, port, tempdata]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchPlans() {
      try {
        const planfound =
          existingPlans && existingPlans?.length > 0
            ? existingPlans?.find(
                (plan) => plan.name === "LTE Mobile Proxies"
              )
            : null;
        planfound
          ? (() => {
              setPlans(planfound);
              setPlansLoading(false);
            })()
          : (() => {
              setPlansLoading(true);
            })();
        const response = planfound
          ? { data: { plan: planfound } }
          : await axios.get("/api/user/plan", {
              params: { type: "LTE Mobile Proxies" },
              withCredentials: true,
              signal: signal,
            });

        const history =
          proxyHistory && proxyHistory?.length > 0
            ? proxyHistory.filter(
                (history) => history.type === "LTE Mobile Proxies"
              )
            : null;
        history?.length > 0
          ? (() => {
              setHistoryLoading(false);
              setHistory(history);
            })()
          : (() => {
              setHistoryLoading(true);
            })();
        const History = history
          ? { data: { history: history } }
          : await axios.get("/api/user/proxy_history", {
              params: { type: "LTE Mobile Proxies" },
              withCredentials: true,
              signal: signal,
            });
        if (!signal.aborted) {
          setHistory(History?.data?.history);
          const data = response?.data?.plan;
          setPlans(data);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching plans:", error);
        }
      } finally {
        if (!signal.aborted) {
          setPlansLoading(false);
          setHistoryLoading(false);
        }
      }
    }
    if (History?.length === 0 || !plans) {
      fetchPlans();
    } else {
      setPlansLoading(false);
      setHistoryLoading(false);
    }
    return () => {
      abortController.abort();
    };
  }, []);

  const handdleGetProxy = async () => {
    try {
      const res = await axios.post(
        "/api/user/get_proxy",
        {
          type: "LTE Mobile Proxies",
          location: selectedIsp?.id,
        },
        {
          withCredentials: true,
        }
      );
      if (res.data.success) {
        setHistory((prev) => [res?.data?.proxies, ...prev]);
        if (res?.data?.user) {
          setUser(res?.data?.user);
        }
        console.log(res?.data);
        toast.success(res?.data?.message || "Proxy Generated Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="px-6 mx-auto dark:text-white">
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Get LTE Proxy
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Other Proxies /
              </a>
            </li>
            <li
              className="font-medium text-primary cursor-pointer"
              onClick={() => window.location.reload()}
            >
              Lte-Proxy
            </li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6 items-strech flex-col lg:flex-row">
        {/* Proxy Form */}
        <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs w-full lg:basis-1/2 h-auto relative">
          <div className="flex flex-col   p-4 sm:p-6">
            <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white flex items-center justify-between gap-2 flex-wrap">
              Lte Mobile Proxy
            </h3>
          </div>
          <div className=" p-4 sm:p-6 pt-0 space-y-2">
            <div className=" flex justify-between gap-2">
              <div className="space-y-2">
                <label className="text-sm">Protocol</label>
                <Slidebutton
                  options={["Http/s", "Socks5"]}
                  onChange={(option) => setport(option)}
                />
              </div>
              <p className="w-full text-end pr-5 ">
                {plansLoading ? (
                  <div className="inline-block h-8 w-20 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
                ) : (
                  <>
                    <span className="font-semibold text-2xl font-sans dark:text-primary">
                      ${plans?.plans?.[0]?.price}
                    </span>{" "}
                    / piece
                  </>
                )}
              </p>
            </div>
            <div className="space-y-2">
              <label className="text-sm">Country</label>
              <CustomInput
                type={"select"}
                valueList={countrylist}
                placeholder={"Select Country"}
                onChange={(e) => {
                  setselectedcountry(e?.code);
                  setValueList({});
                  setselectedcity({});
                }}
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm">City</label>
              <CustomInput
                type={"select"}
                placeholder={
                  selectedcountry ? "Select City" : "Select Country First"
                }
                valueList={citylist}
                value={valueList?.city}
                valueSencitive={true}
                onChange={(e) => {
                  setselectedcity(e);
                  setValueList((prev) => ({ ...prev, city: e?.name }));
                }}
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm">Available ISP</label>

              <CustomInput
                type={"select"}
                placeholder={
                  selectedcity?.name ? "Select ISP" : "Select City First"
                }
                valueList={availableIsp}
                value={valueList?.isp}
                valueSencitive={true}
                onChange={(e) => {
                  setselectedIsp(e);
                  setValueList((prev) => ({ ...prev, isp: e?.type }));
                }}
              />
            </div>

            <div className="col-span-2 flex justify-end pt-4">
              <button
                className="bg-primary text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground hover:bg-accent/90 h-10 px-4 py-2 lg:absolute lg:right-6 lg:bottom-6"
                onClick={() => handdleGetProxy()}
                disabled={loading || cityLoading || !selectedIsp?.id}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
        <div className=" w-full lg:basis-1/2 ">
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs">
            <div className="flex flex-row items-center justify-between  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Proxy History
              </h3>
            </div>
            <div className=" p-4 sm:p-6 pt-0">
              <ProxyHistory History={History} loading={historyLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
