import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft, Home } from "lucide-react";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white dark:bg-bgp flex flex-col items-center justify-center p-4 md:p-8">
      <div className="max-w-xl w-full mx-auto text-center">
        {/* Logo */}
        <Link to="/">
          <div className="rt-Box flex items-center dark:text-white justify-center">
            <span class="font-semibold text-3xl">
              <span class="font-india text-primary">Q</span>socks
            </span>
          </div>
        </Link>

        {/* 404 Text */}
        <h1 className="text-8xl font-bold text-primary mb-4">404</h1>

        {/* Message */}
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-2">
          Page not found
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-8">
          Sorry, we couldn't find the page you're looking for. It might have
          been moved or doesn't exist.
        </p>

        {/* Illustration */}
        <div className="mb-8 relative">
          <div className="w-24 h-24 mx-auto bg-primary/10 dark:bg-primary/20 rounded-full flex items-center justify-center">
            <svg
              className="w-12 h-12 text-primary"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-10">
            <div className="w-32 h-32 rounded-full bg-primary/5 dark:bg-primary/10 animate-pulse"></div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center justify-center gap-2 px-6 py-3 rounded-full border-2 border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-200 font-medium hover:bg-gray-100 dark:hover:bg-gray-800 transition-all"
          >
            <ArrowLeft size={18} />
            Go Back
          </button>

          <Link
            to="/"
            className="inline-flex items-center justify-center gap-2 px-6 py-3 rounded-full bg-primary text-white font-medium hover:bg-primary/90 transition-all"
          >
            <Home size={18} />
            Back to Home
          </Link>
        </div>
      </div>

      {/* Footer Links */}
      <div className="mt-12 text-sm text-gray-500 dark:text-gray-400 flex flex-wrap justify-center gap-x-6 gap-y-2">
        <Link to="/#pricing" className="hover:text-primary transition-colors">
          Pricing
        </Link>
        <Link to="/support" className="hover:text-primary transition-colors">
          Support
        </Link>
        <Link to="/user&guide" className="hover:text-primary transition-colors">
          User Guide
        </Link>
        <a
          href="https://t.me/Nazmul1671"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-primary transition-colors"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
}
