import React from 'react';

const partners = [
  { name: 'BitBrowser', logo: '/bit-browser.webp', width: 60, height: 60 },
  { name: 'GoLogin', logo: '/gologin.webp', width: 60, height: 60 },
  { name: 'MuLogin', logo: '/Mulogin.webp', width: 60, height: 60 },
  { name: 'Incogniton', logo: '/incogniton.svg', width: 60, height: 60 },
  { name: 'Undetectable', logo: '/Undetectable.svg', width: 60, height: 60 },
  { name: 'Capsolver', logo: '/Capsolver.webp', width: 60, height: 60 },
  { name: '', logo: '/Xlogin.png', width: 180, height: 60, noTitle: false },
];

const PartnerLogo = ({ partner }) => (
  <div className="my-5 mr-5 lg:m-5 flex items-center">
    <div className="flex mr-1 items-center justify-center text-indigo-600 min-w-14 min-h-14 dark:text-indigo-300">
      <img
        src={partner.logo}
        alt={partner.name}
        width={partner.width}
        height={partner.height}
        className={partner.class || ''}
      />
    </div>
    {!partner.noTitle && <div className="text-2xl font-bold">{partner.name}</div>}
  </div>
);

const PartnersSection = () => {
  return (
    <div className=" overflow-hidden my-20 px-5 md:px-10 lg:px-20 max-w-[1920px] mx-auto">
      <div className="rt-ContainerInner">
        <div className="mx-auto flex flex-col items-center justify-center w-full py-6 m-4 text-center px-0">
          <h2 
            className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white" 
            style={{display: 'inline-block', verticalAlign: 'top', textDecoration: 'inherit', textWrap: 'balance'}}
          >
            Partners of Qsocks
          </h2>
        </div>
        
        <div className="overflow-x-hidden relative flex items-center justify-center">
          {/* First marquee (visible) */}
          <div className="md:py-12 md:animate-marquee grid whitespace-nowrap md:flex justify-center  text-gray-700 dark:text-white">
            {partners.map((partner, index) => (
              <PartnerLogo key={`partner-1-${index}`} partner={partner} />
            ))}
          </div>
          
          {/* Second marquee (initially hidden on mobile) */}
          <div className="absolute hidden top-12 md:animate-marquee1 whitespace-nowrap md:flex justify-center text-gray-700 dark:text-white">
            {partners.map((partner, index) => (
              <PartnerLogo key={`partner-2-${index}`} partner={partner} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;