import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../context/context";
import { signInWithGoogle } from "../../firbase/firebase";
import { toast } from "react-hot-toast";
import axios from "axios";

const FeatureList = () => {
  const features = [
    "80M+ Original Residential IPs",
    "99.96% Uptime",
    "State, city-level targeting",
    "200+ locations",
    "HTTP(S) & SOCKS5",
  ];

  return (
    <div className="px-5 lg:px-20 flex flex-wrap gap-5 items-center mx-auto justify-center">
      {features.map((feature, index) => (
        <div
          key={index}
          className="flex items-center justify-center gap-2 text-black dark:text-white text-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-circle-check-big w-4 h-4 text-primary group-hover:text-background"
          >
            <path d="M21.801 10A10 10 0 1 1 17 3.335"></path>
            <path d="m9 11 3 3L22 4"></path>
          </svg>
          <span className="rt-Text rt-r-size-3">{feature}</span>
        </div>
      ))}
    </div>
  );
};

export default function HeroSection() {
  const { setUser, user, loading } = useTheme();
  const navigate = useNavigate();

  // Google Sign In

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle();

      const { user } = result;
      if (!user) {
        return;
      }
      const { displayName, email, photoURL, uid } = user;

      const res = await axios.post(
        "/api/google-getway",
        {
          name: displayName,
          email,
          photoURL,
          googleId: uid,
        },
        { withCredentials: true }
      );

      setUser(
        res?.data?.user || {
          name: displayName,
          email,
          profilePic: photoURL,
        }
      );

      toast.success(res?.data?.message || "Successfully logged in with Google");

      if (res?.data?.user?.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/onboard");
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
      toast.error("Failed to login with Google");
    }
  };

  return (
    <section
      id="hero"
      className={`min-h-[90vh] relative flex items-center justify-center from-transparent via-primary/30 to-transparent dark:from-transparent dark:via-primary/0 dark:to-transparen bg-[url('http://localhost:3000/herobg.png')] bg-white dark:bg-bgp  dark:bg-[url('')] bg-no-repeat bg-cover bg-center after:absolute after:bottom-0 after:left-0 after:w-full after:h-[15%] after:bg-gradient-to-b after:from-transparent after:to-[#F3F4F6] dark:after:to-[#1F1F1F] after:pointer-events-none`}
    >
      {/* <div className="min-h-full absolute inset-0 -z-10 flex items-center justify-center bg-gradient-to-t from-transparent via-background to-transparent"></div> */}
      <div className="w-full min-h-full flex items-center justify-center ">
        <div className="w-full h-full flex items-center justify-center">
          <div className="relative min-h-full flex items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full py-6 text-center px-5 lg:px-0">
              <h1 className="text-center sm:w-2/3 text-3xl font-black text-black dark:text-white     tracking-normal md:text-4xl lg:text-6xl !leading-normal">
                <p>
                  Top <span className="text-primary">HTTP SOCKS5</span> Proxy
                  Provider Meets Your Proxy Needs
                </p>
                <div className="flex items-center justify-center py-5">
                  <div className="flex items-center justify-center gap-2 ">
                    <img
                      alt="Trustpilot"
                      loading="lazy"
                      width="0"
                      height="0"
                      decoding="async"
                      style={{ width: "auto", height: "2rem" }}
                      className="invert hue-rotate-180 brightness-90 dark:hue-rotate-0 dark:brightness-100 dark:invert-0"
                      src="https://www.okeyproxy.com/_nuxt/img/trustpilot.98d5fe4.svg"
                    />
                    <span className="text-white text-xs ml-2">
                      <img
                        alt="TrustScore 4.5 out of 5"
                        loading="lazy"
                        width="0"
                        height="0"
                        decoding="async"
                        style={{
                          color: "transparent",
                          width: "auto",
                          height: "1.5rem",
                        }}
                        className=""
                        src="https://www.okeyproxy.com/_nuxt/img/stars.3ff1a8d.svg"
                      />
                    </span>
                    <span className="text-white text-lg ml-2">4.7</span>
                  </div>
                </div>
              </h1>

              <div
                style={{ width: "100%" }}
                className="rt-Flex rt-r-jc-center md:rt-r-jc-center rt-r-w rt-r-my-4 md:rt-r-my-9"
              >
                <div className="flex flex-col items-center justify-center gap-20">
                {!user && !loading &&
                  <div className="flex flex-col md:flex-row gap-5">
                    <Link className="w-full md:w-auto" to="/register">
                    
                      <button className="inline-flex items-center justify-center  whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 border bg-primary text-background shadow-sm border-primary hover:bg-primary/90 h-10 cursor-pointer rounded-full w-full px-10 md:w-auto group gap-4 text-white ">
                        <span className="text-lg">Start free trial</span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="group-hover:translate-x-2 transition-all"
                        >
                          <path
                            d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </Link>

                     <button
                      data-accent-color=""
                      data-radius="full"
                      style={{ cursor: "pointer" }}
                      className="flex py-2 cursor-pointer w-full text-background bg-white px-6 md:w-auto hover:bg-primary text-nowrap gap-3 items-center rounded-full hover:text-white border-primary border dark:border-bgp"
                      onClick={handleGoogleSignIn}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 48 48"
                        enableBackground="new 0 0 48 48"
                        style={{
                          color: "rgba(0, 0, 0, 0.2)",
                          fontSize: "24px",
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#FFC107"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24 c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        ></path>
                        <path
                          fill="#FF3D00"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657 C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        ></path>
                        <path
                          fill="#4CAF50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        ></path>
                        <path
                          fill="#1976D2"
                          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        ></path>
                      </svg>
                      Sign up with Google
                    </button>
                  </div>
                     }

                  <FeatureList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
