import React from 'react'
import { useTheme } from '../../context/context'
import { Moon, Sun } from 'lucide-react';

export default function Page() {
    const { theme, setTheme } = useTheme();
    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
      };
  return (
    <div className="flex items-center justify-center dark:text-white">
      <label className="flex items-center cursor-pointer gap-4">
        {/* Toggle Switch */}
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            checked={theme === 'dark'}
            onChange={toggleTheme}
          />
          <div className="w-8 lg:w-12 h-4 lg:h-5 outline-1 outline-lime-40 rounded-full shadow-inner shadow-gray-400 transition-colors duration-300"
            style={{ '--tw-shadow': 'inset 0 0 7px rgba(0, 0, 0, 0.5)' }}
          >
            <div
              className={`absolute w-4 lg:w-5 h-4 lg:h-5 p-[2px] border-2  shadow-2xl rounded-full  transform transition-transform duration-300 flex justify-center items-center  ${
                theme === 'dark' ? 'translate-x-[16px] lg:translate-x-[30px] bg-white text-black border-bgs' : 'translate-x-0 bg-white text-bgp border-gray-200'
              }`}
            >
               {theme === 'light' ? (
                  <Sun className="w-3 h-3" style={{width:'1em', height:'1em'}}/>
                ) : (
                  <Moon className="w-3 h-3"  style={{width:'1em', height:'1em'}}/>
                )}
            </div>
          </div>
        </div>

        {/* Icon and Label */}
       
        <span className="text-xs font-[600] hidden lg:block">
          {theme === 'light' ? 'Light' : 'Dark'}
        </span>
      </label>
    </div>
  )
}
