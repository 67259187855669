import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate in react-router-dom v6+
import { LogOut, Menu, Wallet } from "lucide-react";
import axios from "axios";
import { useTheme } from "../../context/context";
import ThemeToggle from "../shared/theme";
import { User, User2 } from "lucide-react";

export default function AdminNav({ user, login }) {
  const [isOn, setIsOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginout, setLoginOut] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  //   const context = {}
  const context = useTheme();
  const { isMinimized, setIsMinimized } = useTheme();
  const navigate = useNavigate(); // Replacing useHistory with useNavigate

  const handleToggle = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `/api/setting/toggleLogin`,
        { loginOn: !isOn },
        { withCredentials: true }
      );
      if (response.data.success) {
        setIsOn((prev) => !prev);
      }
    } catch (error) {
      console.error("Error toggling login state:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.get("/api/user/logout", {
        withCredentials: true,
      });
      if (response?.data?.success) {
        console.log(true);
        navigate("/"); // Using navigate instead of history.push
        setLoginOut(true);
        context?.setUser(null);
        context?.customToast(response?.data);
      }
    } catch (error) {
      context?.customToast({ success: false, message: "Something went wrong" });
    }
  };

  return (
    <header className="bg-slate-50 w-full px-[5%] lg:px-20 py-5 text-[#446E6D] border-b-2 border-[#446e6d25] shadow fixed top-0 z-[98] dark:bg-bgs">
      <div className="w-full  max-w-screen-2xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4 justify-center ">
          <Menu
            className="cursor-pointer lg:invisible dark:text-white"
            onClick={() => setIsMinimized(!isMinimized)}
          />
        </div>
        <nav>
          <ul className="w-full flex space-x-5 lg:space-x-12">
            <ThemeToggle />
            <div
              className="flex text-gray-600 font-bold dark:text-primary items-center gap-2 lg:gap-3 text-sm lg:text-base cursor-pointer"
              onClick={() => context?.setPaymentModelOpen(true)}
            >
              <Wallet
                style={{ width: "1.5em", height: "1.5em" }}
                strokeWidth={2.2}
              />
              {context?.loading ? (
                <div className="h-5 w-12 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse "></div>
              ) : (
                <p className="text-gray-700 dark:text-white font-semibold">
                  {context?.user?.balance?.toFixed(2)}$
                </p>
              )}
            </div>
            <li className="grid cursor-pointer">
              <h1 className="text-gray-700 dark:text-gray-100 text-sm lg:text-base font-bold">
                {context?.loading ? (
                  <span className="flex gap-3 items-center">
                    <div className="h-[1.8em] w-[1.8em] bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
                    <div className="h-5 w-24 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse"></div>
                  </span>
                ) : context?.user && context?.user?.profile?.name ? (
                  <span
                    className="flex gap-3 items-center relative"
                    onClick={() => setProfileOpen(!profileOpen)}
                    onBlur={() => setProfileOpen(false)}
                  >
                    {context?.user?.profile?.avatarUrl ===
                    "https://default-avatar-url.com" ? (
                      <User
                        className="dark:text-primary"
                        style={{ width: "1.8em", height: "1.8em" }}
                      />
                    ) : (
                      <img
                        style={{ width: "1.8em", height: "1.8em" }}
                        className="border-2 border-gray-500 rounded-full"
                        src={
                          context?.user?.profile?.avatarUrl ||
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEd2zxEc_4IQ1jHyniHLECu15zRjkHTBJzA&s"
                        }
                        alt=""
                      />
                    )}
                    <span className="text-nowrap">{`${context?.user?.profile?.name}`}</span>

                    {profileOpen && context?.user && context?.user?.role && (
                      <div
                        className="absolute top-[200%] right-0 p-3 w-content bg-white dark:bg-bgs rounded-lg overflow-hidden text-gray-600 dark:text-white flex flex-col gap-2 shadow-lg dark:shadow-gray-900 dark:shadow-md"
                        onMouseLeave={() => setProfileOpen(false)}
                      >
                        <span className="flex gap-3 items-center min-w-max border-b dark:border-gray-700 pb-3 px-2">
                          {context?.user?.profile?.avatarUrl ===
                          "https://default-avatar-url.com" ? (
                            <User2
                              className="border-2 border-gray-500 rounded-full"
                              style={{ width: "2em", height: "2em" }}
                            />
                          ) : (
                            <img
                              style={{ width: "2em", height: "2em" }}
                              className="border-2 border-gray-500 rounded-full"
                              src={
                                context?.user?.profile?.avatarUrl ||
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEd2zxEc_4IQ1jHyniHLECu15zRjkHTBJzA&s"
                              }
                              alt=""
                            />
                          )}
                          <span className="flex flex-col min-w-max">
                            <span>{`${context?.user?.profile?.name}`}</span>
                            <span className="font-normal">{`${context?.user?.email}`}</span>
                          </span>
                        </span>

                        <p
                          title="Logout"
                          className="cursor-pointer text-inherit text-nowrap flex items-center gap-3 hover:bg-primary/15  px-2 py-2 rounded-lg"
                          onClick={handleLogout}
                        >
                          <LogOut style={{ height: "1em", width: "1em" }} />
                          Logout
                        </p>
                      </div>
                    )}
                  </span>
                ) : (
                  "Please log in!"
                )}
              </h1>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
