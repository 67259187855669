import React, { useState, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Search, ChevronDown, User } from "lucide-react";
import "@radix-ui/themes/styles.css";
import { useTheme } from "../../context/context";

// Navigation Menu Components defined inline
const NavigationMenu = forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={`relative z-10 flex max-w-max flex-1 items-center justify-center ${
      className || ""
    }`}
    {...props}
  >
    {children}
  </div>
));
NavigationMenu.displayName = "NavigationMenu";

const NavigationMenuList = forwardRef(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={`group flex flex-1 list-none items-center justify-center space-x-1 ${
      className || ""
    }`}
    {...props}
  />
));
NavigationMenuList.displayName = "NavigationMenuList";

const NavigationMenuItem = ({ children, ...props }) => (
  <li {...props}>{children}</li>
);

const NavigationMenuTrigger = forwardRef(
  ({ className, children, onClick, ...props }, ref) => (
    <button
      ref={ref}
      onClick={onClick}
      className={`group inline-flex w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus:outline-none disabled:opacity-50 ${
        className || ""
      }`}
      {...props}
    >
      {children}
    </button>
  )
);
NavigationMenuTrigger.displayName = "NavigationMenuTrigger";

const NavigationMenuContent = forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`absolute z-10 bg-white shadow-lg rounded-md ${className || ""}`}
    {...props}
  />
));
NavigationMenuContent.displayName = "NavigationMenuContent";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const { user, loading } = useTheme();
  const context = useTheme();
  const navigate = useNavigate();
  const handleMenuToggle = (menuId) => {
    setOpenMenu(openMenu === menuId ? null : menuId);
  };

  return (
    <header className="top-0 z-50 flex-shrink-0 bg-border sticky dark:text-white bg-white dark:bg-bgp overflow-hidden shadow">
      <nav className="px-4 lg:px-0 relative flex justify-between sm:h-20 h-auto items-center
      w-full sm:w-4/5 mx-auto max-w-screen">
        <div className="w-full h-full flex justify-between mx-auto ">
          <div className="w-full">
            <div className=" flex justify-between items-center h-full">
              <Link to="/">
                <div className="rt-Box lg:pr-8 flex items-center justify-center">
                  <span class="font-semibold text-3xl">
                    <span class="font-india text-primary">Q</span>socks
                  </span>
                </div>
              </Link>

              <NavigationMenu className="h-full hidden relative z-10 lg:flex max-w-max flex-1 items-center justify-between px-10">
                <NavigationMenuList className="group flex flex-1 list-none items-center justify-center space-x-2 mb-0">
                  {/* <NavigationMenuItem>
                    <NavigationMenuTrigger
                      onClick={() => handleMenuToggle("products")}
                      className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base font-semibold transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                    >
                      Products{" "}
                      <ChevronDown className="relative -top-[1px] ml-1 h-5 w-5 transition duration-150 group-data-[state=open]:rotate-180" />
                    </NavigationMenuTrigger>
                    {openMenu === "products" && (
                      <NavigationMenuContent className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md p-4" />
                    )}
                  </NavigationMenuItem> */}

                  <NavigationMenuItem>
                    <Link
                      to="/#pricing"
                      onClick={(e) => {
                        e.preventDefault();
                        const pricingSection =
                          document.getElementById("pricing");
                        if (pricingSection) {
                          pricingSection.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                      className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                    >
                      Pricing{" "}
                      {/* <ChevronDown className="relative -top-[1px] ml-1 h-5 w-5 transition duration-150 group-data-[state=open]:rotate-180" /> */}
                      {/* </NavigationMenuTrigger> */}
                    </Link>
                    {/* {openMenu === "pricing" && (
                      <NavigationMenuContent className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md p-4" />
                    )} */}
                  </NavigationMenuItem>

                  {/* <NavigationMenuItem>
                    <NavigationMenuTrigger
                      onClick={() => handleMenuToggle("resources")}
                      className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base font-semibold transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                    >
                      Resources{" "}
                      <ChevronDown className="relative -top-[1px] ml-1 h-5 w-5 transition duration-150 group-data-[state=open]:rotate-180" />
                    </NavigationMenuTrigger>
                    {openMenu === "resources" && (
                      <NavigationMenuContent className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md p-4" />
                    )}
                  </NavigationMenuItem> */}

                  {/* <NavigationMenuItem>
                    <NavigationMenuTrigger
                      onClick={() => handleMenuToggle("solutions")}
                      className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base font-semibold transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                    >
                      Solutions{" "}
                      <ChevronDown className="relative -top-[1px] ml-1 h-5 w-5 transition duration-150 group-data-[state=open]:rotate-180" />
                    </NavigationMenuTrigger>
                    {openMenu === "solutions" && (
                      <NavigationMenuContent className="absolute top-full left-0 w-full bg-white shadow-lg rounded-md p-4" />
                    )}
                  </NavigationMenuItem> */}

                  {/* <NavigationMenuItem> */}
                  <Link
                    to="/user&guide"
                    className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base font- transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                  >
                    Use Guide & Details
                  </Link>
                  {/* </NavigationMenuItem> */}

                  <NavigationMenuItem>
                    <Link
                      to="https://t.me/Nazmul1671"
                      className="group inline-flex w-max items-center justify-center rounded-md px-2 py-4 text-base font- transition-colors hover:text-primary  focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:text-primary"
                      target="_blank"
                    >
                      Contact Us
                    </Link>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>

              <div className="rt-Flex rt-r-ai-center rt-r-jc-center rt-r-gap-4 ml-auto">
                {/* <div className="p-4">
                  <Search className="w-5 h-5 cursor-pointer hover:text-primary" />
                </div>

                <div className="flex items-center">
                  <button
                    type="button"
                    className="flex h-9 w-full items-center whitespace-nowrap rounded-md border border-lime-200 bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-white focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-lime-800 dark:ring-offset-lime-950 dark:focus:ring-lime-300 justify-center gap-1 border-none focus:border-none focus:ring-transparent"
                  >
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
                      ></path>
                    </svg>
                    <span style={{ pointerEvents: "none" }}>English</span>
                  </button>
                </div> */}

                <Link
                  to={
                    loading ? "/#" : !user && !loading ? "/login" : "/onboard"
                  }
                  className={!user && !loading ? "p-4" : "p-4 pr-0"}
                >
                  <button className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-slate-300 underline-offset-4 hover:text-primary h-9 px-4 py-2 text-primary hover:bg-primary/15">
                    {loading ? (
                      <div className="w-16 h-6 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse opacity-70"></div>
                    ) : !user && !loading ? (
                      "Login"
                    ) : (
                      <span className="flex items-center gap-4">
                        {context?.user?.profile?.avatarUrl ===
                        "https://default-avatar-url.com" ? (
                          <User
                            className="dark:text-primary"
                            style={{ width: "1.8em", height: "1.8em" }}
                          />
                        ) : (
                          <img
                            style={{ width: "1.8em", height: "1.8em" }}
                            className="border-2 border-gray-500 rounded-full"
                            src={
                              context?.user?.profile?.avatarUrl ||
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIEd2zxEc_4IQ1jHyniHLECu15zRjkHTBJzA&s"
                            }
                            alt=""
                          />
                        )}
                        <p>Onboard</p>
                      </span>
                    )}
                  </button>
                </Link>

                <Link
                  to={
                    loading
                      ? "/#"
                      : !user && !loading
                      ? "/register"
                      : "/onboard"
                  }
                >
                  <button
                    className="inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 bg-primary text-background shadow hover:bg-primary/90 h-9 px-4 py-2 md:px-6 rounded-full hover:scale-110 transition-transform text-white dark:text-white"
                    style={user && !loading ? { display: "none" } : {}}
                  >
                    Start Free Trial
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
