import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "./context/context";
import Home from "./pages/homePage";
import Onboard from "./pages/onboard";
import LoginPage from "./pages/loginPage";
import Register from "./pages/registerPage";
import BudgedProxy from "./pages/budgetProxy";
import PremiumProxy from "./pages/premiumProxy";
import StaticProxy from "./pages/staticProxy";
import Ipv4Proxy from "./pages/ipv4Proxy";
import Ipv6Proxy from "./pages/ipv6Proxy";
import LteProxy from "./pages/lteProxy";
import ProxyHistory from "./pages/proxyHistory";
import Referral from "./pages/refaral";
import Settings from "./pages/setting";
import Dashboard from "./pages/dashboard";
import CustomerLayout from "./pages/userLayout";
import AddFundsPopup from "./components/shared/ui/AddFundsPopup";
import NotFoundPage from "./pages/noFoundPage";

// Add as a useEffect in your main layout component

function App() {
  useEffect(() => {
    const buttons = document.querySelectorAll("button");

    buttons.forEach((button) => {
      button.addEventListener("mousemove", function (e) {
        // Get button dimensions and position
        const rect = this.getBoundingClientRect();

        // Calculate cursor position relative to button
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Set CSS variables for ripple position
        this.style.setProperty("--x", `${x}px`);
        this.style.setProperty("--y", `${y}px`);
      });

      // This ensures the effect works on first click
      button.addEventListener("mouseenter", function (e) {
        const rect = this.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        this.style.setProperty("--x", `${x}px`);
        this.style.setProperty("--y", `${y}px`);
      });
    });

    // Cleanup listeners on component unmount
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("mousemove", () => {});
        button.removeEventListener("mouseenter", () => {});
      });
    };
  }, []);

  return (
    <ThemeProvider>
      <Router>
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
            style: {
              background: "#333",
              color: "#fff",
            },
            success: {
              duration: 3000,
              style: {
                background: "green",
              },
            },
            error: {
              duration: 5000,
              style: {
                background: "red",
              },
            },
          }}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/onboard"
            element={<CustomerLayout children={<Dashboard />} />}
          />
          <Route
            path="/onboard/budget-proxy"
            element={
              <CustomerLayout>
                <BudgedProxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/rotating-proxy"
            element={
              <CustomerLayout>
                <PremiumProxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/settings"
            element={
              <CustomerLayout>
                <Settings />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/static-proxy"
            element={
              <CustomerLayout>
                <StaticProxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/ipv4-proxy"
            element={
              <CustomerLayout>
                <Ipv4Proxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/ipv6-proxy"
            element={
              <CustomerLayout>
                <Ipv6Proxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/lte-proxy"
            element={
              <CustomerLayout>
                <LteProxy />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/proxies"
            element={
              <CustomerLayout>
                <ProxyHistory />
              </CustomerLayout>
            }
          />
          <Route
            path="/onboard/referral"
            element={
              <CustomerLayout>
                <Referral />
              </CustomerLayout>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <AddFundsPopup />
    </ThemeProvider>
  );
}

export default App;
