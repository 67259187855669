import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Faq() {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: "Which proxy type should I choose?",
      answer: "The best proxy type depends on your specific needs. Residential proxies are ideal for accessing geo-restricted content and web scraping. Datacenter proxies provide faster speeds for tasks that don't require high anonymity. Static proxies are best for account management where consistent IP addresses are needed. For specific recommendations based on your use case, please contact our support team."
    },
    {
      question: "Do you offer a free trial? How to start the free trial?",
      answer: "Yes, we offer a free trial to test our proxy services. To start your free trial, simply create an account on our platform and verify your email address. Once registered, navigate to the dashboard and select 'Free Trial' option. Our free trial includes limited access to our proxy network to help you evaluate the performance and suitability for your needs."
    },
    {
      question: "Do you offer Pay-as-you-go Plan for your proxies?",
      answer: "Yes, we offer flexible Pay-as-you-go plans for all our proxy services. This allows you to only pay for the bandwidth you use without any long-term commitments. Our PAYG plans are perfect for users with variable proxy usage needs or those who want to avoid monthly subscription fees. Check out our pricing page for detailed information on our Pay-as-you-go rates."
    },
    {
      question: "Why should I choose Qsocks?",
      answer: "Qsocks offers industry-leading proxy services with over 80 million residential IPs across 195+ countries. Our proxies provide exceptional reliability with 99.9% uptime, advanced targeting options including city and ISP-level targeting, and competitive pricing. We also offer 24/7 customer support and a user-friendly dashboard to manage your proxies efficiently."
    }
  ];

  return (
    <div className="max-w-[1920px] py-5 sm:py-6 md:py-7 lg:py-8 mx-4 sm:mx-6 md:mx-8 max-w-layout lg:m-auto  mb-20" id='faq'>
      <div className="container mx-auto">
        {/* Header */}
        <div className="container flex flex-col items-center justify-center w-full py-6 m-4 text-center px-0">
          <h2 className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white" 
              style={{display: "inline-block", verticalAlign: "top", textDecoration: "inherit", textWrap: "balance"}}>
            Frequently Asked Questions
          </h2>
        </div>
        
        {/* More FAQs Link */}
        <Link to="/faqs">
          <div className="gap-2 cursor-pointer font-semibold flex text-primary transition-all items-center justify-center w-full text-sm hover:text-primary-foreground">
            MORE FAQS
            <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" 
                fill="currentColor" fillRule="evenodd" clipRule="evenodd">
              </path>
            </svg>
          </div>
        </Link>
        
        {/* Accordion */}
        <div className="md:pt-10 pt-5">
          <div className="w-full rounded-md md:shadow-lg">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="overflow-hidden m-[1px] first:mt-0  dark:bg-[#161616] dark:text-white"
              >
                <h3 className="flex">
                  <button
                    type="button"
                    onClick={() => toggleItem(index)}
                    className={`group flex items-center bg-primary-light justify-between md:text-xl text-lg font-semibold md:px-10 px-0 py-5 text-left md:hover:bg-background w-full md:hover:text-black dark:md:hover:text-primary bg-white dark:bg-bgp ${openItem === index ? ' dark:bg-black dark:text-primary bg-primary text-white' : ''}`}
                  >
                    {item.question}
                    <div className="relative w-6 h-6">
                      {/* Plus icon - shown when closed */}
                      <svg 
                        width="15" 
                        height="15" 
                        viewBox="0 0 15 15" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg" 
                        className={`inset-0 absolute w-6 h-6 transition-all duration-500 ${openItem === index ? 'rotate-270 opacity-0' : ''}`}
                      >
                        <path 
                          d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" 
                          fill="currentColor" 
                          fillRule="evenodd" 
                          clipRule="evenodd"
                        />
                      </svg>
                      
                      {/* Minus icon - shown when open */}
                      <svg 
                        width="15" 
                        height="15" 
                        viewBox="0 0 15 15" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg" 
                        className={`inset-0 absolute w-6 h-6 transition-all duration-500 ${openItem === index ? '' : 'rotate-180 opacity-0'}`}
                      >
                        <path 
                          d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" 
                          fill="currentColor" 
                          fillRule="evenodd" 
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </h3>
                
                {/* Answer content */}
                <div 
                  className={`overflow-hidden text-base transition-all duration-300 ${
                    openItem === index 
                      ? 'max-h-96 p-5 md:p-10' 
                      : 'max-h-0'
                  }`}
                >
                  {openItem === index && <p>{item.answer}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}