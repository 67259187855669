import React from "react";
import { Link } from "react-router-dom";

const ResidentialProxiesSection = () => {
  return (
    <div className="flex flex-wrap pt-16 mx-auto lg:flex-nowrap flex-row-reverse px-5 md:px-10 lg:px-20 max-w-[1920px] text-black dark:text-white">
      {/* img section - hidden on mobile */}
      <div className="lg:flex hidden items-center justify-center w-full lg:w-1/2">
        <img
          src="https://www.eskimoz.co.uk/wp-content/uploads/2024/10/agence-content-550x544-1.png"
          alt="Rotating Residential Proxies"
          width={600}
          height={600}
        />
      </div>

      {/* Content section */}
      <div className="flex flex-wrap flex-col justify-center items-start w-full lg:w-1/2">
        {/* Section heading */}
        <div className="container flex flex-col items-center justify-center w-full py-6 m-4 px-0 mt-0 pt-0 text-left">
          <h2
            className="text-3xl font-black leading-snug tracking-wide text-gray-800 lg:leading-tight lg:text-5xl dark:text-white"
            style={{
              display: "inline-block",
              verticalAlign: "top",
              textDecoration: "inherit",
              textWrap: "balance",
            }}
          >
            Rotating Residential Proxies
          </h2>
        </div>

        {/* Description and CTA */}
        <div className="flex flex-col items-center justify-center w-full">
          <p
            className="text-left text-base text-gray-900 dark:text-gray-300"
            style={{
              display: "inline-block",
              verticalAlign: "top",
              textDecoration: "inherit",
              textWrap: "initial",
              maxWidth: "617.3px",
            }}
          >
            Qsocks offers 80M+ rotating residential IPs from 200+ regions,
            ensuring seamless access and reliable connections for data scraping,
            multi-account management, and other online operations without
            detection or blocking.
          </p>

          <Link
            className="py-4 text-primary self-end font-semibold"
            to="/residential-proxies"
          >
            <div className="gap-2 cursor-pointer font-semibold flex text-primary transition-all items-center justify-center w-full text-sm hover:text-primary/80">
              MORE
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </Link>
        </div>

        {/* Feature boxes */}
        <div className="w-full flex flex-col gap-4">
          {/* Feature 1 - with external link */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-3 bg-white dark:bg-bgp rounded-2xl p-4 shadow"
            href="https://momoproxy.com/blog/usa-residential-proxy"
          >
            <div className="flex text-primary items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 496 512"
                height="100%"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
              </svg>
            </div>
            <div>
              <h4 className="text-2xl font-semibold dark:text-gray-200">
                Top 3 Premium Quality in Residential Proxies
              </h4>
              <p className="mt-1 text-sm leading-7">
                Quality are the same as the residential proxies of Smartproxy,
                Brightdata or Oxylabs, including less than 0.5s avg speed;
                99.96% Success Rate.
              </p>
            </div>
          </a>

          {/* Feature 2 */}
          <div className="flex items-center space-x-3 bg-white dark:bg-bgp rounded-2xl p-4 shadow">
            <div className="flex text-primary items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="100%"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
                <circle cx="9" cy="13" r="1"></circle>
                <circle cx="15" cy="13" r="1"></circle>
                <path d="M18 11.03A6.04 6.04 0 0 0 12.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 0 0 4.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"></path>
              </svg>
            </div>
            <div>
              <h4 className="text-2xl font-semibold dark:text-gray-200">
                Global Reach &amp; Targeting
              </h4>
              <p className="mt-1 text-sm leading-7">
                Access over 80 million residential IPs from 195 countries with
                targeting options including city, state, country level -
                completely free.
              </p>
            </div>
          </div>

          {/* Feature 3 - with external link */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-3 bg-white dark:bg-bgp rounded-2xl p-4 shadow"
            href="https://momoproxy.com/blog/how-to-generate-rotating-residential-ip"
          >
            <div className="flex text-primary items-center justify-center flex-shrink-0 mt-1 rounded-md w-11 h-11">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="100%"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 14v-2h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h2.5"></path>
                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 1.75 1.032"></path>
                <path d="M15.536 17.586a2.123 2.123 0 0 0 -2.929 0a1.951 1.951 0 0 0 0 2.828c.809 .781 2.12 .781 2.929 0c.809 -.781 -.805 .778 0 0l1.46 -1.41l1.46 -1.419"></path>
                <path d="M15.54 17.582l1.46 1.42l1.46 1.41c.809 .78 -.805 -.779 0 0s2.12 .781 2.929 0a1.951 1.951 0 0 0 0 -2.828a2.123 2.123 0 0 0 -2.929 0"></path>
              </svg>
            </div>
            <div>
              <h4 className="text-2xl font-semibold dark:text-gray-200">
                Easily Proxy Setup
              </h4>
              <p className="mt-1 text-sm leading-7">
                Save time with an easy proxy setup—simply select the
                authentication method, location, session type, protocol, and
                generate your proxy list.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResidentialProxiesSection;