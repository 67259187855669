import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft, Copy, Download, Loader } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";
import Slidebutton from "../components/shared/ui/slidebutton";
import Historytable from "../components/shared/ui/historytable";
import Plan from "../components/shared/ui/planscomponent";
import axios from "axios";
import toast from "react-hot-toast";

export default function Page() {
  const [plans, setPlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [proxyhistory, setProxyHistory] = useState([]);
  const [selectedcountry, setselectedcountry] = useState();
  const [countrylist, setCountryList] = useState([]);
  const [Quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.get("/api/user/plan", {
          params: {
            type: "Datacenter Proxies",
          },
          withCredentials: true,
        });
        const getHistory = await axios.get("/api/user/proxy_history", {
          params: {
            type: "Datacenter Proxies",
          },
          withCredentials: true,
        });
        const getLocations = await axios.get("/api/user/server_credentials", {
          params: {
            type: "Datacenter Proxies",
          },
          withCredentials: true,
        });
        setCountryList(getLocations.data?.data);
        setProxyHistory(getHistory.data?.history);
        setPlans(res.data?.plan);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const handdleGetProxy = async () => {
    if (!Quantity || !parseFloat(Quantity) || parseFloat(Quantity) < 1) {
      return toast.error("Please enter a valid quantity");
    }
    setAction(true);
    try {
      const res = await axios.post(
        "/api/user/get_proxy",
        {
          type: "Datacenter Proxies",
          plan: selectedPlan?.id,
          location: selectedcountry,
          quantity: Quantity,
        },
        {
          withCredentials: true,
        }
      );
      if (res.data.success) {
        setProxyHistory((prev) => [...res?.data?.proxies, ...prev]);
        toast.success(res.data?.message || "Proxy Generated Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setAction(false);
    }
  };

  return (
    <div className="px-6 mx-auto  dark:text-white">
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Get IPv4 Proxy
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Other Proxies /
              </a>
            </li>
            <li className="font-medium text-primary">IPv4-Proxy</li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6  items-strech  flex-col lg:flex-row">
        {/* Proxy Form */}
        <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs w-full lg:basis-1/2 h-auto">
          {!selectedPlan ? (
            <>
              <div className="flex flex-col   p-4 sm:p-6">
                <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                  Plans
                </h3>
              </div>
              <Plan
                data={plans}
                onClick={(e) => setSelectedPlan(e)}
                loading={loading}
              />
            </>
          ) : (
            <>
              <div className="flex flex-col   p-4 sm:p-6">
                <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white flex items-center justify-between">
                  <p>Buy Plan</p>
                  <span
                    className="flex items-center text-gray-700 dark:text-white   text-sm cursor-pointer"
                    onClick={() => setSelectedPlan(null)}
                  >
                    <ChevronLeft style={{ width: "1.5em", height: "1.5em" }} />
                    Back{" "}
                  </span>
                </h3>
                <div className="pt-6 space-y-2">
                  <div className=" flex justify-between gap-2">
                    {/* <div className="space-y-2">
                      <label className="text-sm">Protocol</label>
                      <Slidebutton
                        options={["Http/s", "Socks5"]}
                        onChange={(option) => setport(option)}
                      />
                    </div> */}
                    <p className="w-full text-end pr-5 ">
                      <span className="font-semibold text-2xl font-sans dark:text-primary">
                        ${plans?.plans?.[0]?.price}
                      </span>{" "}
                      / piece
                    </p>
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm">Country</label>
                    <CustomInput
                      type={"select"}
                      valueList={countrylist}
                      placeholder={"Select Country"}
                      onChange={(e) => {
                        setselectedcountry(e?.id);
                      }}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm">Quantity</label>
                    <CustomInput
                      type="number"
                      placeholder="Quantity"
                      value={Quantity}
                      onChange={(e) => {
                        e !== ""
                          ? setQuantity(e)
                          : toast.error("Please enter a valid number");
                      }}
                    />
                  </div>

                  <div className="col-span-2 flex justify-end pt-4">
                    <button
                      className="bg-primary  text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground hover:bg-accent/90 h-10 px-4 py-2"
                      onClick={() => handdleGetProxy()}
                      disabled={action}
                    >
                      {action ? (
                        <>
                          {" "}
                          <Loader
                            className="animate-spin"
                            atyle={{ width: "1em", height: "1em" }}
                          />{" "}
                          Generating
                        </>
                      ) : (
                        "Generate"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className=" w-full lg:basis-1/2 ">
          {/* Proxy List Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs">
            <div className="flex flex-row items-center justify-between  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Proxy History
              </h3>
            </div>
            <div className=" p-4 sm:p-6 pt-0">
              <Historytable History={proxyhistory} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
