import React, { useState, useRef, useEffect } from "react";

export default function Slidebutton({
  options = [],
  buttonClass="text-center py-1 text-sm text-black dark:text-white w-[100px]",
  selectedOptionClass="text-center py-1 text-sm text-white w-[100px] ",
  sliderclass="absolute top-0 left-0 w-full h-full bg-primary z-0 rounded w-[100px]",
  containerClass="flex items-center border border-[#446e6d40] rounded w-max text-sm bg-[#F3F4F6] dark:bg-bgp",
  onChange = () => {},
}) {
  const containerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[0] : null);
  const [sliderPosition, setSliderPosition] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current && containerRef.current.children.length > 0) {
      const buttonWidth = containerRef.current.children[0].offsetWidth;
      setSliderWidth(buttonWidth);
    }
  }, [containerRef.current]); 
  if (!options || options.length === 0) {
    return null;
  }

  return (
    <div className={`${containerClass || ""} relative`} ref={containerRef}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`${
            selectedOption === option ? (selectedOptionClass || "") : (buttonClass || "")
          } relative z-10 transition-colors duration-300`}
          onClick={() => {
            setSelectedOption(option);
            setSliderPosition(index * sliderWidth);
            onChange(option);
          }}
        >
          {option}
        </button>
      ))}
      {sliderWidth > 0 && (
        <div
          className={`${sliderclass || ""} transition-transform duration-300`}
          style={{
            transform: `translateX(${sliderPosition}px)`,
            width: sliderWidth,
          }}
        ></div>
      )}
    </div>
  );
}