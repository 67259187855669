import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "flag-icons/css/flag-icons.min.css";

const continentData = {
  "North America": {
    countries: ["us", "ca", "mx"],
    ipCount: 36500000,
    flagships: [
      { code: "us", name: "United States", ipCount: 31030330 },
      { code: "ca", name: "Canada", ipCount: 3881338 },
      { code: "mx", name: "Mexico", ipCount: 192560 },
    ]
  },
  "Europe": {
    countries: ["gb", "de", "fr", "es", "it", "nl", "se", "pl"],
    ipCount: 14200000,
    flagships: [
      { code: "gb", name: "United Kingdom", ipCount: 11070490 },
      { code: "de", name: "Germany", ipCount: 518688 },
      { code: "fr", name: "France", ipCount: 347250 },
    ]
  },
  "Asia": {
    countries: ["in", "jp", "sg", "kr", "cn", "hk", "id", "my", "th", "vn"],
    ipCount: 9800000,
    flagships: [
      { code: "in", name: "India", ipCount: 3352723 },
      { code: "jp", name: "Japan", ipCount: 298760 },
      { code: "sg", name: "Singapore", ipCount: 187230 },
    ]
  },
  "South America": {
    countries: ["br", "ar", "co", "cl", "pe"],
    ipCount: 2800000,
    flagships: [
      { code: "br", name: "Brazil", ipCount: 2224032 },
      { code: "ar", name: "Argentina", ipCount: 156450 },
      { code: "co", name: "Colombia", ipCount: 103280 },
    ]
  },
  "Oceania": {
    countries: ["au", "nz"],
    ipCount: 580000,
    flagships: [
      { code: "au", name: "Australia", ipCount: 469805 },
      { code: "nz", name: "New Zealand", ipCount: 107320 },
    ]
  },
  "Africa": {
    countries: ["za", "eg", "ng", "ke", "ma"],
    ipCount: 780000,
    flagships: [
      { code: "za", name: "South Africa", ipCount: 294210 },
      { code: "eg", name: "Egypt", ipCount: 154620 },
      { code: "ng", name: "Nigeria", ipCount: 118470 },
    ]
  },
};

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Custom hook for detecting when an element is in viewport
const useInView = (options) => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);
    
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isInView];
};

// Counter animation component
const AnimatedCounter = ({ end, duration = 1500, decimals = 0 }) => {
  const [count, setCount] = useState(0);
  const [ref, isInView] = useInView({ threshold: 0.1 });
  
  useEffect(() => {
    let startTime;
    let animationFrame;
    
    if (isInView) {
      const startValue = 0;
      const endValue = end;
      
      const countUp = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        const currentCount = Math.floor(progress * (endValue - startValue) + startValue);
        
        setCount(currentCount);
        
        if (progress < 1) {
          animationFrame = requestAnimationFrame(countUp);
        }
      };
      
      animationFrame = requestAnimationFrame(countUp);
    }
    
    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isInView, end, duration]);
  
  return <span ref={ref}>{formatNumber(count)}</span>;
};

const GlobalCoverage = () => {
  const [activeContinent, setActiveContinent] = useState("North America");
  const [componentInView, setComponentInView] = useState(false);
  const componentRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setComponentInView(true);
        }
      },
      { threshold: 0.1 }
    );
    
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }
    
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  return (
    <div ref={componentRef} className="max-w-[1920px] py-16 mx-auto px-5 md:px-10 lg:px-20">
      <div className="container mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-black text-gray-800 dark:text-white mb-4">
            Global Proxy Coverage
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Our extensive network spans 6 continents with over {componentInView ? <AnimatedCounter end={52} /> : '0'}+ million IPs, giving you unmatched access to global data.
          </p>
        </div>
        
        {/* Statistics */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 mb-16">
          {Object.entries(continentData).map(([continent, data]) => (
            <button
              key={continent}
              className={`p-6 rounded-xl text-center transition-all ${
                activeContinent === continent 
                  ? "bg-primary text-white" 
                  : "bg-white dark:bg-[#161616] border dark:border-bgp hover:border-primary dark:hover:border-primary text-gray-800 dark:text-white"
              }`}
              onClick={() => setActiveContinent(continent)}
            >
              <h3 className="text-sm font-medium mb-2">{continent}</h3>
              <div className="font-bold text-xl">
                {componentInView 
                  ? <AnimatedCounter end={parseFloat((data.ipCount / 1000000).toFixed(1))} decimals={1} /> 
                  : '0'}M+
              </div>
              <div className="text-xs mt-2 opacity-70">
                {componentInView 
                  ? <AnimatedCounter end={data.countries.length} duration={1000} /> 
                  : '0'} Countries
              </div>
            </button>
          ))}
        </div>
        
        {/* Active Continent Details */}
        <div className="bg-white dark:bg-[#161616] rounded-2xl p-6 md:p-8 mb-10 shadow-sm">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-xl font-bold dark:text-white">
              {activeContinent} Coverage
            </h3>
            <span className="text-primary font-medium">
              {componentInView 
                ? <AnimatedCounter end={continentData[activeContinent].ipCount} /> 
                : '0'}+ IPs
            </span>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 ">
            {continentData[activeContinent].flagships.map(country => (
              <div key={country.code} className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-[#1f1f1f] rounded-lg">
                <div className="flex-shrink-0">
                  <span className={`fi fi-${country.code} w-10 h-10 rounded-full`}></span>
                </div>
                <div>
                  <h4 className="font-medium dark:text-white">{country.name}</h4>
                  <div className="text-sm text-primary">
                    {componentInView 
                      ? <AnimatedCounter end={country.ipCount} duration={1800} /> 
                      : '0'} IPs
                  </div>
                </div>
              </div>
            ))}
            <div className="hidden sm:block"></div>
            
            {/* Additional countries indicator */}
            <div className="flex items-center justify-center p-4 bg-gray-50 dark:bg-[#1f1f1f] rounded-lg border border-dashed border-gray-300 dark:border-gray-700">
              <Link to="/login" className="text-primary text-decoration-none text-center">
                +{componentInView 
                  ? <AnimatedCounter end={continentData[activeContinent].countries.length - 3} duration={800} /> 
                  : '0'} more countries
                <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">View All</div>
              </Link>
            </div>
          </div>
          
          {/* Flag ribbon */}
          <div className="mt-10 flex justify-center gap-2 flex-wrap">
            {continentData[activeContinent].countries.map(code => (
              <span key={code} className={`fi fi-${code} w-8 h-8 rounded-full shadow-sm`}></span>
            ))}
            <span className="w-8 h-8 rounded-full bg-gray-100 dark:bg-[#1f1f1f] flex items-center justify-center text-xs font-bold">+</span>
          </div>
        </div>
        
        <div className="text-center">
          <Link to="/location">
            <button className="bg-primary text-white px-6 py-3 rounded-full font-medium hover:bg-primary/90 transition-colors">
              Explore All 195+ Countries
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GlobalCoverage;