import { Copy } from "lucide-react";
import React from "react";
import toast from "react-hot-toast";

export default function Historytable({ History, loading }) {
  const getDate = (date) => {
    const d = new Date(date);
    const dateStr = d.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return dateStr;
  };

  // Skeleton loader component
  const TableSkeleton = () => {
    return (
      <table className="w-full text-sm md:text-base">
        <thead className="sticky bg-[#F3F4F6] dark:bg-bgp top-0 dark:text-primary font-normal text-sm">
          <tr>
            <th className="py-2">Ip</th>
            <th className="py-2">Date</th>
            <th className="py-2">Plan</th>
            <th className="py-2">Action</th>
          </tr>
        </thead>
        <tbody className="text-center text-xs md:text-sm">
          {[...Array(5)].map((_, index) => (
            <tr
              key={index}
              className="border-b border-[#446e6d25] dark:border-dark-300"
            >
              <td className="pt-3 pb-2">
                <div className="h-5 bg-gray-200 dark:bg-gray-700 mx-auto w-24 rounded animate-pulse"></div>
              </td>
              <td className="pt-3 pb-2">
                <div className="h-5 bg-gray-200 dark:bg-gray-700 mx-auto w-16 rounded animate-pulse"></div>
              </td>
              <td className="pt-3 pb-2">
                <div className="h-5 bg-gray-200 dark:bg-gray-700 mx-auto w-12 rounded animate-pulse"></div>
              </td>
              <td className="pt-3 pb-2 flex justify-center">
                <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="lg:p-4 rounded-lg ">
      <div
        className="bg-muted text-gray-700 dark:text-white min-h-[360px] max-h-[360px] overflow-y-auto custom-scrollbar relative"
        style={{ borderRadius: "0px" }}
      >
        {loading ? (
          <TableSkeleton />
        ) : History?.length > 0 ? (
          <table className="w-full text-sm md:text-base">
            <thead className="sticky bg-[#F3F4F6] dark:bg-bgp top-0 dark:text-primary font-normal text-sm ">
              <tr className="">
                <th className="py-2 bg-[#F3F4F6] dark:bg-bgp rounded-l-md overflow-hidden">Ip</th>
                <th className="py-2 bg-[#F3F4F6] dark:bg-bgp">Date</th>
                <th className="py-2 bg-[#F3F4F6] dark:bg-bgp">Plan</th>
                <th className="py-2 bg-[#F3F4F6] dark:bg-bgp rounded-r-md overflow-hidden">Action</th>
              </tr>
            </thead>
            <tbody className="text-center text-xs md:text-sm mx-4">
              {History?.map((item, index) => (
                <tr
                  key={index}
                  className="border-b border-[#446e6d25] dark:border-dark-300 text-xs"
                >
                  <td className="max-w-[5vw] overflow-hidden truncate pt-3">
                    <span className="">{item?.proxy}...</span>
                  </td>
                  <td className="pt-2">{getDate(item?.createdAt)}</td>
                  <td className="pt-2">24h</td>
                  <td className="flex justify-center pt-3 mb-1">
                    <button
                      className="p-1.5 rounded-lg bg-primary text-white"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.proxy);
                        toast.success("Proxy Copied Successfully");
                      }}
                    >
                      <Copy style={{ width: "1em", height: "1em" }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="h-[295px] flex items-center justify-center dark:text-gray-400">
            No history found
          </div>
        )}
      </div>
    </div>
  );
}
