import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTheme } from "../context/context";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { signInWithGoogle } from "../firbase/firebase";

const LoginPage = () => {
  const schema = z.object({
    email: z
      .string()
      .min(1, "Email is required")
      .email("Please enter a valid email"),
    password: z.string().min(1, "Password is required"),
  });

  const {
    register,
    handleSubmit: validateSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { user, loading, setUser } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user) {
      if (user?.role === "admin") {
        navigate("/admin/dashboard", {replace: true });
      } else if (user?.role === "user") {
        navigate("/onboard", {replace: true });
      }
    }
  }, [user, loading, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle();

      const { user } = result;
      if (!user) {
        return;
      }
      const { displayName, email, photoURL, uid } = user;

      const res = await axios.post(
        "/api/google-getway",
        {
          name: displayName,
          email,
          photoURL,
          googleId: uid,
        },
        { withCredentials: true }
      );

      setUser(
        res?.data?.user || {
          name: displayName,
          email,
          profilePic: photoURL,
        }
      );

      toast.success(res?.data?.message || "Successfully logged in with Google");

      if (res?.data?.user?.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/onboard");
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
      toast.error("Failed to login with Google");
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await axios.post("/api/login", data, {
        withCredentials: true,
      });
      toast.success(res.data.message);
      setUser(res?.data?.user);
      if (res?.data?.user?.role === "admin") {
        navigate("/admin");
      } else {
        navigate("/onboard");
      }
      reset();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      validateSubmit(onSubmit)();
    }
  };
  return (
    <div className="w-full min-h-svh dark:text-white bg-gray-200 dark:bg-inherit">
      <div className="fixed p-6">
        <div className="flex justify-center gap-2 md:justify-start">
          <Link to="/">
            <div className="rt-Box lg:pr-8 flex items-center justify-center dark:text-white">
              <span className="font-semibold text-3xl">
                <span className="font-india text-primary">Q</span>socks
              </span>
            </div>
          </Link>
        </div>
      </div>
      <div className="hidden flex-col gap-4 p-6 md:p-10 bg-background fixed">
        <div className="flex justify-center gap-2 md:justify-start">
          <Link to="/">
            <div className="rt-Box lg:pr-8 flex items-center justify-center dark:text-white">
              <span className="font-semibold text-3xl">
                <span className="font-india text-primary">Q</span>socks
              </span>
            </div>
          </Link>
        </div>

        {/* 
        <div className="flex flex-1 items-center justify-center">
          <div className="rt-Flex rt-r-fd-column rt-r-gap-1 rt-r-my-4">
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              <strong className="text-primary">80M+</strong> Global Residential
              IPs, Covering <strong className="text-primary">200+</strong>{" "}
              Countries
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Supports <strong className="text-primary">HTTP(S）SOCKS5</strong>{" "}
              Proxy
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Supports all devices:{" "}
              <strong className="text-primary">
                Windows, IOS, Android, Linux
              </strong>
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Supports All Use Cases:{" "}
              <strong className="text-primary">
                Anti-detection Browser, Emulator, Scraper etc
              </strong>
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Suitable for{" "}
              <strong className="text-primary">
                eCommerce, Social Media, Multi-Accounts, SEO &amp; SEM
              </strong>
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Worldwide IP coverage, supporting international operations.
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Trusted by 10K+ partners, offering stable and reliable services.
            </span>
            <span className="rt-Text rt-r-size-2 sm:rt-r-size-3 leading-8">
              Provides high-speed data transfer and low latency.
            </span>
          </div>
        </div> */}
      </div>

      {/* Right side - Login Form */}
      <div className="flex flex-col gap-4 p-6 md:p-10 dark:bg-secendery  min-h-screen items-center">
        <div className="flex flex-1 items-center justify-center">
          <div className="w-full relative max-w-md">
            <div>
              <div className="flex flex-col gap-6 mt-20 md:mt-0">
                <div className="rounded-xl bg-white dark:bg-[#161616] text-gray-700 dark:text-primary shadow">
                  <div className="flex flex-col space-y-1.5 p-6 text-center">
                    <h3 className="font-semibold tracking-tight text-xl">
                      Welcome back
                    </h3>
                    <p className="text-sm text-muted-foreground">
                      Login with your Google account
                    </p>
                  </div>
                  <div className="p-6 pt-0">
                    <form onSubmit={validateSubmit(onSubmit)}>
                      <div className="grid gap-6">
                        <div className="flex flex-col gap-4">
                          <button
                            type="button"
                            onClick={handleGoogleSignIn}
                            className="inline-flex justify-center gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 text-background shadow hover:bg-primary/90 h-9 px-4 py-2 w-full !flex items-center rounded-full dark:bg-white text-white dark:text-inherit hover:text-white bg-primary"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 48 48"
                              enableBackground="new 0 0 48 48"
                              style={{
                                color: "rgba(0, 0, 0, 0.2)",
                                fontSize: "30px",
                                verticalAlign: "middle",
                                cursor: "pointer",
                              }}
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="#FFC107"
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
                                c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
                                c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                              <path
                                fill="#FF3D00"
                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
                                C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                              ></path>
                              <path
                                fill="#4CAF50"
                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
                                c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                              ></path>
                              <path
                                fill="#1976D2"
                                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
                                c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                            </svg>
                            <span className="font-bold text-center ">
                              Sign in with Google
                            </span>
                          </button>
                        </div>

                        <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
                          <span className="relative z-[9] bg-white dark:bg-[#161616] px-2 text-muted-foreground">
                            Or continue with
                          </span>
                        </div>

                        <div className="grid gap-6">
                          <div className="space-y-2 relative">
                            <label
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              htmlFor="email-input"
                            >
                              Email
                            </label>
                            <div className="relative flex items-center">
                              <input
                                className="flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:file:text-slate-50 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300"
                                placeholder="Enter your email"
                                // id="email-input"
                                // name="email"
                                // value={formData.email}
                                {...register("email")}
                                // onChange={handleInputChange}
                              />
                            </div>
                            {errors.email && (
                              <p className="text-red-500 text-xs mt-1 absolute bottom-[-18px]">
                                {errors.email.message}
                              </p>
                            )}
                          </div>

                          <div className="space-y-2 relative">
                            <div className="flex items-center">
                              <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                htmlFor="password-input"
                              >
                                Password
                              </label>
                              <button
                                type="button"
                                className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 hover:text-primary h-9 px-4 py-2 ml-auto text-sm underline-offset-4 hover:underline"
                              >
                                Forgot your password?
                              </button>
                            </div>
                            <div className="relative flex items-center">
                              <input
                                className="flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:file:text-slate-50 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300"
                                placeholder="Enter your password"
                                // id="password-input"
                                type="password"
                                // name="password"
                                // value={formData.password}
                                // onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                {...register("password")}
                              />
                            </div>
                            {errors.password && (
                              <p className="text-red-500 text-xs mt-1 absolute bottom-[-18px]">
                                {errors.password.message}
                              </p>
                            )}
                          </div>

                          <button
                            className="mt-2 inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 bg-primary text-background shadow hover:bg-primary/90 h-9 px-4 py-2 w-full rounded-full text-white"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Login
                          </button>
                        </div>

                        <div className="text-center text-sm">
                          Don't have an account?{" "}
                          <button
                            type="button"
                            className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 hover:text-primary h-9 py-2 underline px-0 underline-offset-4"
                          >
                            <Link to="/register">Sign up</Link>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-balance mt-6 text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 [&_a]:hover:text-primary">
              By clicking continue, you agree to our{" "}
              <Link to="/terms-of-service">
                <button className="items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 underline-offset-4 hover:text-primary h-9 text-xs underline p-0 inline-block">
                  Terms of Service
                </button>
              </Link>{" "}
              and{" "}
              <Link to="/privacy">
                <button className="items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 underline-offset-4 hover:text-primary h-9 text-xs underline p-0 inline-block">
                  Privacy Policy
                </button>
              </Link>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
