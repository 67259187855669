import React, { useEffect, useState } from "react";
import { ChevronDown, Loader } from "lucide-react";

export default function CustomInput({
  placeholder,
  value,
  valueList,
  onChange,
  type,
  name,
  className,
  valueSencitive,
  readOnly,
  onBlur,
  filteroff,
  containerClassName
}) {
  const [inputValue, setInputValue] = useState(value || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setfilter] = useState(false);

  useEffect(() => {
    if (valueSencitive) {
      setInputValue(value || "");
    }
  }, [value]);

  const handleInputKeyDown = () => {
    if (type === "select" && !filter && !filteroff) {
      setfilter(true);
    }
  };

  return (
    <div
      className={`flex items-center w-full rounded-md border border-[#446e6d40] bg-[#F3F4F6] dark:bg-bgp px-3 py-2 text-gray-700 dark:text-white focus-within:ring-2 focus-within:ring-ring relative ${containerClassName || ""}`}
      onFocus={() => setShowDropdown(true)}
      onBlur={() => setShowDropdown(false)}
    >
      <input
        name={name || ""}
        readOnly={readOnly}
        onChange={(e) => {
          if(readOnly) return;
          if (
            type === "number" &&
            (isNaN(e.target.value) || e.target.value === " ")
          )
            return;
          setInputValue(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
        onKeyDown={handleInputKeyDown}
        type={type === "number" ? "text" : type}
        onBlur={() => {
          if (onBlur) onBlur(inputValue);
        }}
        placeholder={placeholder || ""}
        value={inputValue}
        className={`w-full bg-transparent outline-none placeholder:text-muted-foreground text-sm ${
          readOnly ? "caret-transparent cursor-default" : ""
        } ${className || ""}`}
      />
      {type === "select" && (
        <ChevronDown
          className={`h-4 w-4 opacity-50 transition-all duration-500 ${
            showDropdown ? "rotate-180" : ""
          }`}
        />
      )}
      {type === "select" && Array.isArray(valueList) && (
        <div
          className="absolute inset-0 w-full h-max max-h-[300px] cursor-pointer top-[110%] left-0 bg-white dark:bg-bgs rounded-md overflow-hidden text-gray-600 dark:text-white flex flex-col items-start gap-[2px] shadow-md px-2 py-2 border-2 border-primary/30 dark:border-white/20 z-20 overflow-y-scroll custom-scrollbar"
          style={{ display: showDropdown ? "" : "none" }}
        >
          {valueList?.length > 0 ? (
            (filter
              ? valueList.filter((item) =>
                  item?.name?.toLowerCase().includes(inputValue.toLowerCase())
                )
              : valueList
            ).length > 0 ? (
              (filter
                ? valueList.filter((item) =>
                    item?.name?.toLowerCase().includes(inputValue.toLowerCase())
                  )
                : valueList
              ).map((item, index) => (
                <span
                  key={index}
                  className="w-full py-1 hover:bg-primary/15 px-3 rounded-md text-sm"
                  onMouseDown={() => {
                    setInputValue(item?.name);
                    setShowDropdown(false);
                    setfilter(false);
                    if (onChange) onChange(item);
                  }}
                >
                  {item?.name}
                </span>
              ))
            ) : (
              <span className="w-full py-1 px-3 flex items-center justify-center text-sm ">
                Not Found
              </span>
            )
          ) : (
            <span className="w-full py-1 px-3 flex items-center justify-center text-sm">
              <Loader
                className="animate-spin"
                style={{ height: "1em", width: "1em" }}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
}
