import React, { useEffect, useState } from "react";
import { CircleDollarSign, Plus, Copy, Download } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";
import Plans from "../components/shared/ui/planscomponent";
import Slidebutton from "../components/shared/ui/slidebutton";
import { useTheme } from "../context/context";
import ConfirmPrompt from "../components/shared/ui/ConfirmPromt";
import axios from "axios";
import StatsComponent from "../components/shared/ui/statsComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function Page() {
  const [section, setSection] = useState("Premium Residential Proxies");
  const { user, loading, setPaymentModelOpen, plans, setUser, stats } =
    useTheme();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmFunction, setConfirmFunction] = useState(null);
  const [buyLoading, setBuyLoading] = useState(false);
  const [confirmDetails, setConfirmDetails] = useState({
    title: "Confirm Action",
    message: "Are you sure you want to proceed?",
    confirmText: "Confirm",
    cancelText: "Cancel",
  });
  const navigate = useNavigate();

  useEffect(() => {
    console.log(plans?.length);
    if (plans && plans.length > 0) {
      setSelectedPlan(plans[0]);
    }
  }, [plans]);

  const handleBuyPlan = async (item) => {
    setBuyLoading(true);
    try {
      const res = await axios.post(
        "/api/user/get_plan",
        {
          type: section,
          amount: item?.amount,
        },
        {
          withCredentials: true,
        }
      );
      if (res?.data?.success) {
        toast.success(res.data.message || "Plan purchased successfully");
        if (res?.data?.user) {
          setUser(res?.data?.user);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setBuyLoading(false);
    }
  };

  const handlePlanClick = (item) => {
    setConfirmDetails({
      title: "Buy Plan",
      message: "Are you sure you want to buy this plan?",
      confirmText: "Buy",
      cancelText: "Cancel",
    });
    setShowConfirm(true);
    setConfirmFunction(() => () => {
      handleBuyPlan(item);
      setShowConfirm(false);
      setConfirmDetails({
        title: "Confirm Action",
        message: "Are you sure you want to proceed?",
        confirmText: "Confirm",
        cancelText: "Cancel",
      });
    });
  };

  return (
    <div className="px-6 mx-auto  dark:text-white">
      <ConfirmPrompt
        show={showConfirm}
        onClose={() => {
          setConfirmFunction(null);
          setShowConfirm(false);
        }}
        onConfirm={confirmFunction}
        loading={buyLoading}
        confirmText={confirmDetails.confirmText || "Confirm"}
        title={confirmDetails.title || "Confirm Action"}
        message={confirmDetails.message || "Are you sure you want to proceed?"}
      />
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Dashboard
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Onboard /
              </a>
            </li>
            <li className="font-medium text-primary">Dashboard</li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6 items-start flex-col lg:flex-row">
        {/* Proxy Form */}
        <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs w-full lg:basis-1/3 h-auto">
          <div className="flex items-center justify-between  p-6">
            <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
              Wallet
            </h3>
            {/* //add bottom outline with ofset2 */}
            {/* not woeking */}
            <p className="text-[10px] md:text-xs dark:text-primary text-gray-900 cursor-pointer relative inline-block group">
              <span>Check History</span>
              <span className="absolute bottom-0 left-1/2 w-0 h-[1px] bg-gray-700 dark:bg-primary group-hover:w-full transition-all duration-300 ease-in-out transform -translate-x-1/2 group-hover:left-0 group-hover:translate-x-0"></span>
            </p>
          </div>
          <div className="p-6 pt-0 space-y-2">
            <div className="col-span-2 flex justify-between">
              <span className="flex gap-2 items-center text-2xl dark:text-white text-gray-900 price">
                <CircleDollarSign
                  className="text-primary"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
                {loading ? (
                  <div className="h-8 w-14 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse  price"></div>
                ) : (
                  `${user?.balance?.toFixed(2)}`
                )}
              </span>
              <button
                className="bg-primary text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-opacity duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground hover:opacity-80 px-2 py-1"
                onClick={() => setPaymentModelOpen(true)}
              >
                <Plus style={{ width: "1.2em", height: "1.2em" }} /> Add Funds
              </button>
            </div>
          </div>
        </div>
        <div className=" w-full h-full ">
          {/* Credentials Card */}

          {/* Proxy List Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs">
            <div className="flex flex-row items-center justify-between p-6 pb-2">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Announcement
              </h3>
            </div>
            <div className="p-6 pt-0">
              <p className="text-sm md:text-base dark:text-gray-400 line-clamp-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                tempore, consequatur iste repellat natus quia, voluptatibus
                dolores magni, quos quidem. Quisquam, quod. Quisquam, quod.
                dolores magni, quos quidem. Quisquam, quod. Quisquam, quod.
                dolores magni, quos quidem. Quisquam, quod. Quisquam, quod.
                dolores magni, quos quidem. Quisquam, quod. Quisquam, quod.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded-lg border border-[#446e6d25] bg-white shadow dark:bg-bgs">
        <div className="flex flex-col space-y-1.5 p-4 lg:p-6">
          <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
            Overview
          </h3>
        </div>
        <div className="px-4 lg:px-6">
          <Slidebutton
            options={[
              "Premium Residential Proxies",
              "Residential Proxies",
              "Static Residential Proxies",
              "LTE Mobile Proxies",
              "Datacenter Proxies",
              "Datacenter IPv6 Proxies",
            ]}
            buttonClass="text-center py-2 text-xs text-black w-[180px] dark:text-gray-400"
            selectedOptionClass="text-center py-1 text-xs text-white w-[180px] "
            sliderclass="absolute top-0 left-0 w-full h-full bg-primary z-0 rounded w-[180px]"
            containerClass=" items-center border border-[#446e6d40] rounded w-max text-sm bg-[#F3F4F6] dark:bg-bgp hidden 2xl:flex"
            onChange={(option) => {
              if (option === "Residential Proxies") {
                setSection("Budget Residential Proxies");
              } else {
                setSection(option);
              }
              const plan = plans?.find((plan) => plan.name === option);
              plan ? setSelectedPlan(plan) : setSelectedPlan(null);
            }}
          />
          <CustomInput
            readOnly={true}
            type={"select"}
            valueList={[
              {
                name: "Premium Residential Proxies",
                value: "Premium Residential Proxies",
              },
              {
                name: "Residential Proxies",
                value: "Budget Residential Proxies",
              },
              {
                name: "Static Residential Proxies",
                value: "Static Residential Proxies",
              },
              {
                name: "LTE Mobile Proxies",
                value: "LTE Mobile Proxies",
              },
              {
                name: "Datacenter Proxies",
                value: "Datacenter Proxies",
              },
              {
                name: "Datacenter IPv6 Proxies",
                value: "Datacenter IPv6 Proxies",
              },
            ]}
            onChange={(option) => {
              setSection(option.value);
              const plan = plans?.find((plan) => plan.type === option.value);
              console.log("plan", option);
              plan ? setSelectedPlan(plan) : setSelectedPlan(null);
            }}
            containerClassName={"2xl:hidden"}
          />
        </div>
        <div className="flex lg:gap-6 flex-col lg:flex-row">
          <div className="basis-1/2 p-4 lg:p-6  flex flex-col">
            <div className="flex flex-col space-y-1.5">
              <h3 className="text-base lg:text-lg font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Usage
              </h3>
            </div>
            <StatsComponent
             section={section}
              PickPlanClick={() => {
                if (selectedPlan?.plans?.length > 0) {
                  const randomIndex = Math.floor(
                    Math.random() * selectedPlan?.plans?.length
                  );
                  const randomPlan = selectedPlan?.plans[randomIndex];
                  setConfirmDetails({
                    title: `${randomPlan?.amount} GB for ${randomPlan?.price} $`,
                    message: "Are you sure you want to pick this plan?",
                    confirmText: "Pick",
                    cancelText: "Cancel",
                  });
                  setConfirmFunction(() => () => {
                    handleBuyPlan(selectedPlan?.plans[randomIndex]);
                    setShowConfirm(false);
                    setConfirmDetails({
                      title: "Confirm Action",
                      message: "Are you sure you want to proceed?",
                      confirmText: "Confirm",
                      cancelText: "Cancel",
                    });
                  });
                  setShowConfirm(true);
                } else {
                  toast.error("No plans available");
                }
              }}
              InUseClick={() => {
                section === "Premium Residential Proxies"
                  ? navigate("/onboard/rotating-proxy")
                  : navigate("/onboard/budget-proxy");
              }}
            />
          </div>

          <div className="basis-1/2  ">
            <div className="flex flex-col space-y-1.5 p-4 pt-0 lg:p-6 lg:pb-0">
              <h3 className="text-base lg:text-lg font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Plans
              </h3>
            </div>
            <Plans data={selectedPlan} onClick={handlePlanClick} />
          </div>
        </div>
      </div>
      {/* <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs mt-6">
      <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
            Transactions
          </h3>
        </div>
        <div className="p-6 pt-0 grid grid-row-2 gap-4 max-h-[400px] overflow-y-auto custom-scrollbar">
          <table className="w-full text-sm md:text-base ">
            <thead className="sticky top-0 bg-[#E6E5FD] dark:bg-[#302F47] pt-2">
              <tr>
                <th className="py-2">Amount</th>
                <th className="py-2">Date</th>
                <th className="py-2">Method</th>
              </tr>
            </thead>
            <tbody className="text-center text-xs md:text-sm ">
              {Array.from({ length: 20 }).map((_, i) => (
                <tr key={i} className=" border-b dark:border-gray-800">
                  <td className="pt-3 pb-1">10$</td>
                  <td>2021-09-30</td>
                  <td>Crypto</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}
