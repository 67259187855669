import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../context/context";
import Navbar from "../components/user/navbar";
import Sidebar from "../components/user/sidebar";
import { BookOpen, HelpCircle } from "lucide-react";

export default function UserLayout({ children }) {
  const { user, loading, loading2 } = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !loading && !loading2) {
      navigate("/login");
    }
  }, [user, loading, loading2, navigate]);

  return (
    <div className="w-full dark:bg-[#161616] bg-gray-200 ">
      <Navbar />
      <div className="flex">
        <Sidebar />
        <div className="w-full mt-24 pb-6">
          {children}
          <div className="mt-6 rounded-lg border border-[#446e6d25] bg-white shadow dark:bg-bgs p-6 m-6">
            <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white mb-4">
              Help Center
            </h3>
            <div className="flex flex-col lg:flex-row gap-4">
              {/* FAQ Section */}
              <div
                className="flex items-center gap-4 p-4 border border-[#446e6d25] rounded-lg bg-[#F3F4F6] dark:bg-bgp w-full lg:w-1/2 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/#faq");
                }}
              >
                <HelpCircle className="text-primary w-8 h-8" />
                <div>
                  <h4 className="text-base font-semibold text-gray-700 dark:text-white">
                    FAQ
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Browse the FAQ list and get answers instantly
                  </p>
                </div>
              </div>

              {/* User Guide Section */}
              <div className="flex items-center gap-4 p-4 border border-[#446e6d25] rounded-lg bg-[#F3F4F6] dark:bg-bgp w-full lg:w-1/2  cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                navigate("/user&guide");
              }}
              >
                <BookOpen className="text-primary w-8 h-8" />
                <div>
                  <h4 className="text-base font-semibold text-gray-700 dark:text-white">
                    User Guide
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Learn how to configure the use of proxies
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
