import React, { useEffect, useState } from "react";
import { ChevronDown, Copy, Download, Loader } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";
import Slidebutton from "../components/shared/ui/slidebutton";
import axios from "axios";
import toast from "react-hot-toast";
import Plans from "../components/shared/ui/planscomponent";
import { useTheme } from "../context/context";
import ConfirmPrompt from "../components/shared/ui/ConfirmPromt";

export default function Page() {
  const [countrylist, setcountrylist] = useState([]);
  const [selectedcountry, setselectedcountry] = useState({});
  const [selectedstate, setselectedstate] = useState({});
  const [selectedcity, setselectedcity] = useState({});
  const [quantity, setquantity] = useState(1);
  const [rotation, setrotation] = useState("Rotating");
  const [port, setport] = useState("Http/s");
  const [Format, setFormat] = useState("");
  const [Proxy, setProxy] = useState("");
  const [valueList, setValueList] = useState({});
  const [Credentials, setCredentials] = useState({
    username: "Not Generated",
    password: "Not Generated",
  });
  const [plan, setPlan] = useState({});
  const [section, setSection] = useState("Generate");
  const [rotationTime, setRotationTime] = useState(15);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmFunction, setConfirmFunction] = useState(null);
  const [buyLoading, setBuyLoading] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUser } = useTheme();

  useEffect(() => {
    setLoadingPlan(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "/api/user/budget_residential_location",
          {
            withCredentials: true,
            signal: signal,
          }
        );
        const planResponse = await axios.get("/api/user/plan", {
          params: { type: "Budget Residential Proxies" },
          withCredentials: true,
          signal: signal,
        });
        if (!signal.aborted) {
          setPlan(planResponse?.data?.plan);
          setcountrylist(response?.data?.locations);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching country data:", error);
        }
      } finally {
        if (!signal.aborted) {
          setLoadingPlan(false);
        }
      }
    };
    fetchCountry();

    return () => {
      abortController.abort();
    };
  }, []);

  const handleGenerate = async () => {
    if (!selectedcountry?.code || !selectedcity?.code || !quantity) {
      toast.error("Please select all the fields");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/user/generate_budget",
        {
          country: selectedcountry?.code,
          state: selectedstate?.code,
          city: `-city-${selectedcity?.code}`,
          quantity: quantity,
          lifetime: rotation === "Rotating" ? rotationTime : 0,
          rotation: rotation === "Rotating" ? "random" : "sticky",
          port: port === "Http/s" ? "http|https" : "socks5",
        },
        {
          withCredentials: true,
        }
      );
      if (response?.data) {
        setProxy(response?.data?.proxies?.[0]);
        const proxy = response?.data?.proxies?.[0];
        const [host, port, user, pass] = proxy.split(":");
        setCredentials({
          username: user,
          password: pass,
        });
      }
      toast.success(response?.data?.message || "Proxy generated successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getIpInFormat = (ip, format) => {
    if (!ip) return "";
    if (!format) return ip;
    const [host, port, user, pass] = ip.split(":");
    switch (format) {
      case "host:port:user:pass":
        return `${host}:${port}:${user}:${pass}`;

      case "host:port@user:pass":
        return `${host}:${port}@${user}:${pass}`;

      case "user:pass:host:port":
        return `${user}:${pass}:${host}:${port}`;

      case "user:pass@host:port":
        return `${user}:${pass}@${host}:${port}`;

      default:
        return ip;
    }
  };

  const handleDownload = () => {
    if (!Proxy) {
      toast.error("No proxy available to download");
      return;
    }

    try {
      const formattedProxy = getIpInFormat(Proxy, Format);
      const blob = new Blob([formattedProxy], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `proxy_${new Date()
        .toISOString()
        .slice(0, 10)}.txt`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      }, 100);

      toast.success("Proxy file downloaded successfully");
    } catch (error) {
      console.error("Download error:", error);
      toast.error("Failed to download proxy file");
    }
  };

  const handleBuyPlan = async (item) => {
    if (!item) return;
    setBuyLoading(true);
    try {
      const res = await axios.post(
        "/api/user/get_plan",
        {
          type: "Budget Residential Proxies",
          amount: item?.amount,
        },
        { withCredentials: true }
      );
      if (res?.data?.success) {
        toast.success(res.data.message || "Plan purchased successfully");
        if (res?.data?.user) {
          setUser(res?.data?.user);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setBuyLoading(false);
    }
  };

  const handlePlanClick = (item) => {
    setShowConfirm(true);
    setConfirmFunction(() => () => {
      handleBuyPlan(item);
    });
  };

  return (
    <div className="px-4 sm:px-6 mx-auto  dark:text-white ">
      <ConfirmPrompt
        show={showConfirm}
        onClose={() => {
          setConfirmFunction(null);
          setShowConfirm(false);
        }}
        onConfirm={confirmFunction}
        loading={buyLoading}
        confirmText="Buy"
        title="Buy Plan"
        message="Are you sure you want to buy this plan?"
      />
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Create Residential Proxy
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Residential Proxies /
              </a>
            </li>
            <li className="font-medium text-primary">Residential-Proxy</li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6 items-strech flex-col lg:flex-row lg:min-h-[400px]">
        {/* Proxy Form */}
        <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs w-full lg:basis-1/2 h-auto relative ">
          <div className="flex flex-col p-4 sm:p-6">
            <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white flex items-center justify-between gap-4 flex-wrap">
              Residential Proxy
              <Slidebutton
                options={["Generate", "Plans", "Usage"]}
                onChange={(option) => setSection(option)}
              />
            </h3>
          </div>
          {section === "Generate" ? (
            <div className=" p-4 sm:p-6 pt-0 space-y-2">
              <div className=" flex justify-start gap-2 flex-col sm:flex-row sm:gap-5">
                <div className="space-y-2">
                  <label className="text-sm">Protocol</label>
                  <Slidebutton
                    options={["Http/s", "Socks5"]}
                    onChange={(option) => setport(option)}
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm">Rotation</label>
                  <Slidebutton
                    options={["Rotating", "Sticky"]}
                    onChange={(option) => setrotation(option)}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm">Country</label>
                <CustomInput
                  type={"select"}
                  valueList={countrylist}
                  placeholder={"Select Country"}
                  onChange={(e) => {
                    setselectedcountry(e);
                    setValueList({});
                    setselectedstate({});
                    setselectedcity({});
                  }}
                />
              </div>
              {/* <div className="space-y-2">
                <label className="text-sm">State</label>
                <CustomInput
                  type={"select"}
                  value={valueList?.state}
                  valueSencitive={true}
                  placeholder={
                    selectedcountry?.states
                      ? "Select State"
                      : "Select Country First"
                  }
                  valueList={selectedcountry?.states}
                  onChange={(e) => {
                    setselectedstate(e);
                    setValueList((prev) => ({ ...prev, state: e?.name }));
                  }}
                />
              </div> */}

              <div className="space-y-2">
                <label className="text-sm">City</label>
                <CustomInput
                  type={"select"}
                  placeholder={
                    selectedcountry?.cities
                      ? "Select City"
                      : "Select Country First"
                  }
                  valueList={selectedcountry?.cities?.options}
                  value={valueList?.city}
                  valueSencitive={true}
                  onChange={(e) => {
                    setselectedcity(e);
                    setValueList((prev) => ({ ...prev, city: e?.name }));
                  }}
                />
              </div>
              {rotation === "Rotating" && (
                <div className="space-y-2">
                  <label className="text-sm">Rotation</label>
                  <CustomInput
                    type={"select"}
                    readOnly={true}
                    filteroff={true}
                    value={"15 Minutes"}
                    placeholder={"Select Rotation Time"}
                    valueList={[
                      { name: "15 Minutes", value: 15 },
                      { name: "30 Minutes", value: 30 },
                      { name: "1 Hour", value: 60 },
                      { name: "4 Hour", value: 240 },
                      { name: "6 Hour", value: 360 },
                      { name: "12 Hour", value: 720 },
                      { name: "18 Hour", value: 1080 },
                      { name: "1 Day", value: 1440 },
                    ]}
                    onChange={(e) => {
                      setRotationTime(e?.value);
                    }}
                  />
                </div>
              )}
              <div className="space-y-2">
                <label className="text-sm">Format</label>
                <CustomInput
                  type={"select"}
                  placeholder={"Select Format"}
                  valueList={[
                    { name: "host:port:user:pass" },
                    { name: "host:port@user:pass" },
                    { name: "user:pass:host:port" },
                    { name: "user:pass@host:port" },
                  ]}
                  onChange={(e) => setFormat(e?.name)}
                />
              </div>
              {/* <div className="space-y-2">
                        <label className="text-sm">Quantity</label>
                        <input
                          type="number"
                          min="1"
                          max="5000"
                          defaultValue="1"
                          className="flex h-10 w-full rounded-md border border-[#446e6d25]   bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white"
                          value={quantity}
                          onChange={(e) => setquantity(e.target.value)}
                        />
                      </div> */}
              <div className="col-span-2 flex justify-end pt-4 lg:absolute right-6 bottom-6">
                <button
                  className="bg-primary  text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground hover:bg-accent/90 h-10 px-4 py-2 items-center"
                  disabled={loading}
                  onClick={handleGenerate}
                >
                  {loading ? (
                    <Loader
                      className="animate-spin"
                      style={{ width: "1em", height: "1em" }}
                    />
                  ) : (
                    ""
                  )}
                  Generate
                </button>
              </div>
            </div>
          ) : section === "Plans" ? (
            <Plans
              data={plan}
              onClick={handlePlanClick}
              loading={loadingPlan}
            />
          ) : (
            <></>
          )}
        </div>
        <div className=" w-full lg:basis-1/2 ">
          {/* Credentials Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs ">
            <div className="flex flex-col space-y-1.5  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Credentials
              </h3>
            </div>
            <div className=" p-4 sm:p-6 pt-0 grid grid-row-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm">Username</label>
                <div className="relative">
                  <input
                    name="text"
                    readOnly
                    value={Credentials?.username}
                    className="flex h-10 w-full rounded-md border border-[#446e6d25]   bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-gray-400"
                  />
                  <button
                    className="bg-primary  text-white absolute right-2 top-1/2 -translate-y-1/2 h-7 w-7 flex items-center justify-center rounded-md hover:bg-accent hover:text-accent-foreground"
                    onClick={() => {
                      navigator.clipboard.writeText(Credentials?.username);
                      toast.success("Username copied to clipboard");
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm">Password</label>
                <div className="relative">
                  <input
                    name="text2"
                    type="password"
                    readOnly
                    value={Credentials?.password}
                    className="flex h-10 w-full rounded-md border border-[#446e6d25]   bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-gray-400"
                  />
                  <button
                    className="bg-primary  text-white absolute right-2 top-1/2 -translate-y-1/2 h-7 w-7 flex items-center justify-center rounded-md hover:bg-accent hover:text-accent-foreground"
                    onClick={() => {
                      navigator.clipboard.writeText(Credentials?.password);
                      toast.success("Password copied to clipboard");
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Proxy List Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs mt-6">
            <div className="flex flex-row items-center justify-between  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Proxy
              </h3>
              <div className="flex gap-2">
                <button
                  className="bg-primary  text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-[#446e6d25]   bg-background hover:bg-accent hover:text-accent-foreground h-9 px-3"
                  onClick={() => handleDownload()}
                >
                  <Download className="h-4 w-4" />
                  <span className="hidden md:block">Download</span>
                </button>
                <button
                  className="bg-primary  text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-[#446e6d25]   bg-background hover:bg-accent hover:text-accent-foreground h-9 px-3"
                  onClick={() => {
                    navigator.clipboard.writeText(getIpInFormat(Proxy, Format));
                    toast.success("Proxy copied to clipboard");
                  }}
                >
                  <Copy className="h-4 w-4" />
                  <span className="hidden md:block">Copy</span>
                </button>
              </div>
            </div>
            <div className=" p-4 sm:p-6 pt-0">
              <div className="bg-muted p-4 rounded-lg bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white">
                <code className="text-sm break-all dark:text-gray-400">
                  {!getIpInFormat(Proxy, Format) ? (
                    <p className=" text-center">{"No Proxy Generated"}</p>
                  ) : (
                    getIpInFormat(Proxy, Format)
                  )}
                </code>
              </div>
            </div>
            <div className=" p-4 sm:p-6 pt-0">
              <div className="bg-muted p-4 rounded-lg bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white">
                {Proxy ? (
                  getIpInFormat(Proxy, Format)
                    .split(":")
                    .map((item, index) => (
                      <span className="flex gap-2" key={index}>
                        <code>
                          {
                            (Format ? Format : "host:port:user:pass").split(
                              ":"
                            )[index]
                          }
                        </code>
                        {" : "}
                        <code
                          className="text-sm break-all dark:text-gray-400 cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(item);
                            toast.success("Copied Successfully");
                          }}
                        >
                          {item}
                        </code>
                      </span>
                    ))
                ) : (
                  <div className="bg-muted p-4 rounded-lg bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-gray-400 text-center">
                    No proxy generated yet. Please generate a proxy first.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
