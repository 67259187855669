import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ThemeProviderContext = createContext({
  theme: "system",
  setTheme: () => null,
});

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "app-theme",
}) {
  const [theme, setTheme] = useState(
    () => localStorage.getItem(storageKey) || defaultTheme
  );
  const [user, setUser] = useState(null);
  const [plans, setPlans] = useState(null);
  const [proxyHistory, setProxyHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [error, setError] = useState(null);
  const [login, setLogin] = useState(false);
  const [confirmFunction, setConfirmFunction] = useState(null);
  const [isMinimized, setisMinimized] = useState(false);
  const [PaymentModelOpen, setPaymentModelOpen] = useState(false);
  const [stats, setStarts] = useState(null);
  // const fetchedOnce = useRef(false);
  // Set Axios default baseURL if needed
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

  const customToast = (value) => {
    if (value.success) {
      toast.success(value.message);
    } else {
      toast.error(value.message);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setisMinimized(localStorage.getItem("slideBar") === "true");

    const fetchUserData = async () => {
      if (user) return;

      setLoading(true);
      setLogin(true);

      try {
        const planRequest = axios
          .get("/api/user/plan", {
            withCredentials: true,
            signal: signal,
          })
          .catch((error) => {
            if (error.name !== "AbortError") {
              console.error("Error fetching plan:", error);
            }
            return { data: null };
          });

        const historyRequest = axios
          .get("/api/user/proxy_history", {
            withCredentials: true,
            signal: signal,
          })
          .catch((error) => {
            if (error.name !== "AbortError") {
              console.error("Error fetching proxy history:", error);
            }
            return { data: null };
          });

        const userInfoRequest = axios.get("/api/getuserinfo", {
          withCredentials: true,
          signal: signal,
        });

        const statsRequest = axios.get("/api/user/get_starts", {
          withCredentials: true,
          signal: signal,
        });
        const [planResponse, historyResponse, userResponse, statsResponse] =
          await Promise.all([
            planRequest,
            historyRequest,
            userInfoRequest,
            statsRequest,
          ]);

        if (planResponse.data) {
          setPlans(planResponse.data.plan);
        }

        if (historyResponse.data) {
          setProxyHistory(historyResponse.data.history);
        }

        if (statsResponse.data) {
          setStarts(statsResponse.data);
        }

        if (userResponse.data) {
          setUser(userResponse.data.user);
        } else {
          setUser(null);
        }
      } catch (err) {
        if (err.name !== "AbortError") {
          customToast({ success: false, message: err.message });
          setError(err.message);
          setUser(null);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
          setLoading2(false);
        }
      }
    };

    fetchUserData();
    return () => {
      abortController.abort();
    };
  }, [user]);

  useEffect(() => {
    const savedTheme = localStorage.getItem(storageKey);

    if (!savedTheme) {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";
      localStorage.setItem(storageKey, systemTheme);
      setTheme(systemTheme);
    }
  }, [storageKey]);

  useEffect(() => {
    const root = document.documentElement;
    root.classList.remove("light", "dark");
    if (theme === "light" || theme === "dark") {
      root.classList.add(theme);
    }
  }, [theme]);

  const setIsMinimized = (value) => {
    localStorage.setItem("slideBar", value);
    setisMinimized(value);
  };

  const value = {
    theme,
    setTheme: (newTheme) => {
      localStorage.setItem(storageKey, newTheme);
      setTheme(newTheme);
    },
    user,
    setUser,
    login,
    setLogin,
    loading,
    error,
    customToast,
    confirmFunction,
    setConfirmFunction,
    isMinimized,
    setIsMinimized,
    PaymentModelOpen,
    setPaymentModelOpen,
    loading2,
    plans,
    stats,
    proxyHistory,
  };

  return (
    <ThemeProviderContext.Provider value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};
