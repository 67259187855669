import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "../context/context";
import { symbol, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { signInWithGoogle } from "../firbase/firebase";

const LoginPage = () => {
  const schema = z
    .object({
      email: z
        .string()
        .min(1, "Email is required")
        .email("Please enter a valid email"),
      password: z
        .string()
        .min(1, "Password is required")
        .min(6, "Password must be at least 6 characters")
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      confirmPassword: z
        .string()
        .min(1, "Please confirm your password")
        .min(6, "Password must be at least 6 characters")
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      code: z
        .string()
        .min(6, "Please enter the 6-digit verification code")
        .max(6, "Code must be 6 characters")
        .regex(/^\d{6}$/, "Code must contain 6 digits"),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords do not match",
      path: ["confirmPassword"],
    });

  const {
    register,
    handleSubmit: validateSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    getValues,
    trigger,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      code: "",
    },
  });

  ///stage

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    code: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [countdown, setCountdown] = useState();

  const { user, loading, setUser } = useTheme();
  const queryParams = new URLSearchParams(location.search);
  const intervalRef = useRef(null);
  const refParam = queryParams.get("ref");


  useEffect(() => {
    if (!loading && user) {
      if (user?.role === "admin") {
        navigate("/admin/dashboard", { replace: true });
      } else if (user?.role === "user") {
        navigate("/onboard", { replace: true });
      }
    }
  }, [user, loading, navigate]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleSendCode = async () => {
    const isEmailValid = await trigger("email");
    if (!isEmailValid) {
      return;
    }
    const email = getValues("email");
    try {
      const response = await axios.post("/api/register_verification", {
        email: email,
      });

      if (response?.data) {
        setCountdown(60);

        intervalRef.current = setInterval(() => {
          setCountdown((prevCount) => {
            if (prevCount <= 1) {
              clearInterval(intervalRef.current);
              return 0;
            }
            return prevCount - 1;
          });
        }, 1000);

        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        "/api/register",
        { ...data, refCode: refParam },
        {
          withCredentials: true,
        }
      );
      if (response?.data) {
        toast.success(response?.data?.message);
        navigate("/onboard");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle();

      const { user } = result;
      if (!user) {
        return;
      }
      const { displayName, email, photoURL, uid } = user;

      const res = await axios.post(
        "/api/google-getway",
        {
          name: displayName,
          email,
          photoURL,
          googleId: uid,
        },
        { withCredentials: true }
      );

      setUser(
        res?.data?.user || {
          name: displayName,
          email,
          profilePic: photoURL,
        }
      );

      toast.success(res?.data?.message || "Successfully logged in with Google");

      if (res?.data?.user?.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/onboard");
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
      toast.error("Failed to login with Google");
    }
  };

  return (
    <div className="w-full min-h-svh dark:text-white bg-gray-200 dark:bg-inherit">
      {/* Left side - Information Panel */}
      <div className="fixed p-6">
        <div className="flex justify-center gap-2 md:justify-start">
          <Link to="/">
            <div className="rt-Box lg:pr-8 flex items-center justify-center dark:text-white">
              <span className="font-semibold text-3xl">
                <span className="font-india text-primary">Q</span>socks
              </span>
            </div>
          </Link>
        </div>
      </div>
      <div className="hidden flex-col gap-4 p-6 md:p-10 bg-background fixed">
        <div className="flex justify-center gap-2 md:justify-start">
          <Link to="/">
            <div className="rt-Box lg:pr-8 flex items-center justify-center dark:text-white">
              <span className="font-semibold text-3xl">
                <span className="font-india text-primary">Q</span>socks
              </span>
            </div>
          </Link>
        </div>
      </div>

      {/* Right side - Login Form */}
      <div className="flex flex-col gap-4 p-6 md:p-10 dark:bg-secendery  min-h-screen items-center">
        <div className="flex flex-1 items-center justify-center">
          <div className="w-full relative max-w-md">
            <div>
              <div className="flex flex-col gap-6 mt-20 md:mt-0">
                <div className="rounded-xl bg-white dark:bg-[#161616] text-gray-700 dark:text-primary shadow">
                  <div className="flex flex-col space-y-1.5 p-6 text-center">
                    <h3 className="font-semibold tracking-tight text-xl">
                      Register
                    </h3>
                    <p className="text-sm text-muted-foreground">
                      Register with your Google account
                    </p>
                  </div>
                  <div className="p-6 pt-0">
                    <form onSubmit={validateSubmit(onSubmit)}>
                      <div className="grid gap-6">
                        <div className="flex flex-col gap-4">
                          <button
                            type="button"
                            className="inline-flex justify-center gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 text-background shadow hover:bg-primary/90 h-9 px-4 py-2 w-full !flex items-center rounded-full dark:bg-white text-white dark:text-inherit hover:text-white bg-primary"
                            onClick={handleGoogleSignIn}
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 48 48"
                              enableBackground="new 0 0 48 48"
                              style={{
                                color: "rgba(0, 0, 0, 0.2)",
                                fontSize: "30px",
                                verticalAlign: "middle",
                                cursor: "pointer",
                              }}
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="#FFC107"
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
                                c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
                                c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                              <path
                                fill="#FF3D00"
                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
                                C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                              ></path>
                              <path
                                fill="#4CAF50"
                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
                                c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                              ></path>
                              <path
                                fill="#1976D2"
                                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
                                c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                            </svg>
                            <span className="font-bold text-center ">
                              Sign up with Google
                            </span>
                          </button>
                        </div>

                        <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
                          <span className="relative z-[9] bg-white dark:bg-[#161616] px-2 text-muted-foreground">
                            Or continue with
                          </span>
                        </div>

                        <div className="grid gap-6">
                          <div className="space-y-2 ">
                            <label
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              htmlFor="email-input"
                            >
                              Email
                            </label>
                            <div className="relative flex items-center ">
                              <input
                                className="flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:file:text-slate-50 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300"
                                placeholder="Enter your email"
                                {...register("email")}
                              />
                              <button
                                type="button"
                                className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 h-9 px-4 py-2 text-sm ml-2 bg-primary text-white hover:text-white"
                                onClick={handleSendCode}
                                disabled={countdown > 0}
                              >
                                {countdown > 0 ? (
                                  <span className="text-sm text-white">
                                    {countdown}s Resend
                                  </span>
                                ) : (
                                  <span className="text-sm text-white">
                                    Send Code
                                  </span>
                                )}
                              </button>
                              {errors.email && (
                                <span className="text-red-500 text-xs absolute bottom-[-18px] left-0">
                                  {errors.email.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="space-y-4 relative">
                            {/* <VerificationInput
                              onComplete={(code) =>
                                setFormData({ ...formData, code: code })
                              }
                            /> */}
                            <VerificationInput
                              onComplete={(code) => {
                                setValue("code", code, {
                                  shouldValidate: true,
                                });
                                setVerificationCompleted(true);
                              }}
                              error={errors.code?.message}
                            />
                            {errors.code && (
                              <p className="text-xs text-red-500 mt-1 absolute bottom-[-20px] left-0">
                                {errors.code.message}
                              </p>
                            )}
                          </div>

                          <div className="space-y-2 relative">
                            <div className="flex items-center">
                              <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                htmlFor="password-input"
                              >
                                Password
                              </label>
                            </div>
                            <div className="relative flex items-center">
                              <input
                                className="flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:file:text-slate-50 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300"
                                placeholder="Enter your password"
                                id="password-input"
                                type="password"
                                {...register("password")}
                              />
                              {errors.password && (
                                <span className="text-red-500 text-xs absolute bottom-[-18px] left-0">
                                  {errors.password.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="space-y-2 relative">
                            <div className="flex items-center">
                              <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                htmlFor="password-input"
                              >
                                Confirm Password
                              </label>
                            </div>
                            <div className="relative flex items-center">
                              <input
                                className="flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-slate-950 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:file:text-slate-50 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300"
                                placeholder="Enter your password"
                                id="password-input"
                                type="password"
                                {...register("confirmPassword")}
                              />
                            </div>
                            {errors.confirmPassword && (
                              <span className="text-red-500 text-xs absolute bottom-[-18px] left-0">
                                {errors.confirmPassword.message}
                              </span>
                            )}
                          </div>

                          <button
                            className="inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 bg-primary text-background shadow hover:bg-primary/90 h-9 px-4 py-2 w-full rounded-full text-white"
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </div>

                        <div className="text-center text-sm">
                          Already have an account?{" "}
                          <button
                            type="button"
                            className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 hover:text-primary h-9 py-2 underline px-0 underline-offset-4"
                          >
                            <Link to="/login">Sign in</Link>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-balance mt-6 text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 [&_a]:hover:text-primary">
              By clicking continue, you agree to our{" "}
              <Link to="/terms-of-service">
                <button className="items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 underline-offset-4 hover:text-primary h-9 text-xs underline p-0 inline-block">
                  Terms of Service
                </button>
              </Link>{" "}
              and{" "}
              <Link to="/privacy">
                <button className="items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-slate-300 underline-offset-4 hover:text-primary h-9 text-xs underline p-0 inline-block">
                  Privacy Policy
                </button>
              </Link>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

const VerificationInput = ({ onComplete }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  // Initialize input refs
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 6);
    // Focus first input on mount
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Only allow one digit per input
    if (value.length > 1) {
      return;
    }

    // Update the code state
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Auto-focus next input if value is entered
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    // Call onComplete if all digits are filled
    if (newCode.every((digit) => digit !== "") && index === 5) {
      onComplete && onComplete(newCode.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();

    // If pasted content is 6 digits, fill all inputs
    if (/^\d{6}$/.test(pastedData)) {
      const digits = pastedData.split("");
      setCode(digits);

      // Focus last input
      inputRefs.current[5].focus();

      // Call onComplete
      onComplete && onComplete(pastedData);
    }
  };
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <label className="text-sm font-medium leading-none">
          Verification Code
        </label>
      </div>
      <div className="flex gap-2 justify-between">
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            className="flex h-12 w-12 items-center justify-center rounded-md border border-slate-200 bg-transparent text-center text-xl font-semibold shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 dark:border-slate-800 dark:focus-visible:ring-slate-300"
            type="text"
            inputMode="numeric"
            maxLength={1}
            pattern="\d"
            value={code[index]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={index === 0 ? handlePaste : null}
            autoComplete="one-time-code"
          />
        ))}
      </div>
    </div>
  );
};
