import React from "react";
import { useTheme } from "../../../context/context";

export default function StatsComponent({
  section,
  horizontal = false,
  PickPlanClick = () => {},
  InUseClick = () => {},
}) {
  const { stats } = useTheme();
  const statsExtrantion = () => {
    if (!stats || !stats?.success) return null;
    const currentstat =
      section === "Premium Residential Proxies"
        ? stats?.premiumStat
        : stats?.budgetStat;
    return {
      remainingFlow: currentstat?.availableTraffic,
      totalTraffic:
        Number(currentstat?.availableTraffic) +
        Number(currentstat?.usedTraffic),
      usedTraffic: currentstat?.usedTraffic,
    };
  };

  return (
    <div
      className={`h-full flex flex-col ${
        !horizontal ? "lg:flex-row" : "justify-between"
      } gap-4 bg-white dark:bg-bgs rounded-lg  p-4 lg:p-6 pl-0 lg:pl-0`}
    >
      {/* Remaining Total Flow */}
      <div
        className={`flex flex-col items-start justify-between w-full  ${
          !horizontal ? "lg:border-r lg:w-1/2" : "border-b h-1/2 pb-8"
        } border-gray-200 dark:border-gray-700`}
      >
        <h3 className="text-base font-semibold text-gray-700 dark:text-white mb-4">
          Remaining Total Flow:
        </h3>
        <p className="text-2xl font-bold text-primary dark:text-white mb-6">
          {statsExtrantion(stats)?.remainingFlow?.toFixed(2)} GB
        </p>
        <button
          className="text-primary border border-primary rounded-md px-4 py-2 text-sm font-medium hover:bg-primary hover:text-white transition"
          onClick={() => {
            PickPlanClick();
          }}
        >
          Pick A Random Plan?
        </button>
      </div>

      {/* Details */}
      <div
        className={`flex flex-col items-start justify-between w-full lg:w-1/2 ${
          !horizontal ? " lg:w-1/2" : " h-1/2 "
        }`}
      >
        <h3 className="text-base font-semibold text-gray-700 dark:text-white mb-4">
          Details:
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
          total traffic: {statsExtrantion(stats)?.totalTraffic}; Used:{" "}
          {statsExtrantion(stats)?.usedTraffic?.toFixed(2)} GB
        </p>
        <div className="w-full bg-gray-200 dark:bg-gray-700 h-1 rounded-full mb-6">
          <div
            className="bg-primary h-1 rounded-full"
            style={{
              width: `${
                (parseFloat(statsExtrantion(stats)?.usedTraffic) /
                  parseFloat(statsExtrantion(stats)?.totalTraffic)) *
                  100 || 0
              }%`,
            }}
          ></div>
        </div>
        <button
          className="text-primary border border-primary rounded-md px-4 py-2 text-sm font-medium hover:bg-primary hover:text-white transition"
          onClick={() => {
            InUseClick();
          }}
        >
          Come Into Use
        </button>
      </div>
    </div>
  );
}
