import React, { useState } from "react";
import CustomInput from "./customInput";
import { useTheme } from "../../../context/context";
import toast from "react-hot-toast";
import { Loader } from "lucide-react";
import axios from "axios";

const AddFundsPopup = () => {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cryptomous");
  const [cryptoCurrency, setCryptoCurrency] = useState("LTC");
  const { PaymentModelOpen, setPaymentModelOpen } = useTheme();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setPaymentModelOpen(false);
  };

  const handleSubmit = async () => {
    if (!amount || !paymentMethod) {
      toast.error("Please fill all the fields");

      return;
    }
    if (amount < 0.1) {
      toast.error("Amount must be greater than 0.1");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/payment",
        { amount: amount, method: paymentMethod, currency: cryptoCurrency },
        {
          withCredentials: true,
        }
      );
      if (res?.data?.success) {
        window.open(res?.data?.url, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  if (!PaymentModelOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100] p-4">
      <div className="bg-white dark:bg-bgs rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          ✕
        </button>

        <h3 className="text-xl font-semibold text-primary dark:text-white mb-6">
          Add Funds
        </h3>

        <div className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm dark:text-white">Amount</label>
            <CustomInput
              type="number"
              placeholder="Enter amount"
              value={amount}
              valueSencitive={true}
              onChange={(value) => {
                setAmount(value);
              }}
              onBlur={(value) => {
                if (value && !isNaN(parseFloat(value))) {
                  setAmount(parseFloat(value).toFixed(2));
                }
              }}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm dark:text-white">Payment Method</label>
            <CustomInput
              type="select"
              readOnly={true}
              placeholder="Select payment method"
              valueList={[{ name: "Cryptomous", code: "cryptomous" }]}
              value={"Cryptomous"}
              onChange={(option) => setPaymentMethod(option.code)}
            />
          </div>
          {paymentMethod === "cryptomous" && (
            <div className="space-y-2">
              <label className="text-sm dark:text-white">Currency</label>
              <CustomInput
                type="select"
                placeholder="Select cryptocurrency"
                value={"Ltc"}
                valueList={[
                  { name: "Doge", code: "DOGE" },
                  { name: "Trx", code: "TRX" },
                  { name: "Ltc", code: "LTC" },
                  { name: "Usdt", code: "USDT" },
                ]}
                onChange={(option) => setCryptoCurrency(option.code)}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-primary text-white inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 px-4 py-2 hover:bg-primary/80"
          >
            {loading ? (
              <Loader
                className="animate-spin"
                style={{ width: "1em", height: "1em" }}
              />
            ) : (
              "Add"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFundsPopup;
