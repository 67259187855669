import React from "react";
import { ChevronDown, Copy, Download } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";
import { useTheme } from "../context/context";
import toast from "react-hot-toast";

export default function Page() {
  const { user, loading } = useTheme();
  const dummyTableData = [
    {
      ip: "budget.digiproxy.cc:11000:ajh5qD6GqHpU-country-us-city-granville:hgdkfjhg327823t872asdf",
      date: "2021-09-30",
      plan: "1w",
      action: <Copy style={{ width: "1em", height: "1em" }} />,
    },
    {
      ip: "budget.digiproxy.cc:11000:ajh5qD6GqHpU-country-us-city-granville:hgdkfjhg327823t872asdf",
      date: "2021-09-30",
      plan: "2w",
      action: <Copy style={{ width: "1em", height: "1em" }} />,
    },
  ];
  return (
    <div className="px-6 mx-auto  dark:text-white">
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Referral
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Onboard /
              </a>
            </li>
            <li className="font-medium text-primary">Referral</li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6 items-start flex-col">
        {/* Proxy Form */}
        <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs w-full lg:basis-1/2 h-auto">
          <div className="flex flex-row items-center justify-between  p-4 sm:p-6">
            <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
              Your Referral Link
            </h3>
          </div>
          <div className=" p-4 sm:p-6 pt-0">
            <div className="bg-muted p-4 rounded-lg bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-gray-400 cursor-pointer h-max relative ">
              {loading ? (
                <div className="h-6 w-full bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              ) : (
                `https://qsocks.net/register?ref=${user?.refCode}`
              )}
              <span
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-primary rounded-lg flex items-center justify-center animate-pulse hover:animate-none cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://qsocks.net/register?ref=${user?.refCode}`
                  );
                  toast.success("Referral link copied to clipboard!");
                }}
              >
                <Copy
                  className="text-white"
                  style={{ width: "1em", height: "1em" }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className=" w-full lg:basis-1/2 ">
          {/* Credentials Card */}
          {/* <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs ">
            <div className="flex flex-col space-y-1.5  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Credentials
              </h3>
            </div>
            <div className=" p-4 sm:p-6 pt-0 grid grid-row-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm">Username</label>
                <div className="relative">
                  <input
                    readOnly
                    value="ajh5qD6GqHpU"
                    className="flex h-10 w-full rounded-md border border-[#446e6d25]   bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white"
                  />
                  <button className="bg-primary  text-white absolute right-2 top-1/2 -translate-y-1/2 h-7 w-7 flex items-center justify-center rounded-md hover:bg-accent hover:text-accent-foreground">
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm">Password</label>
                <div className="relative">
                  <input
                    type="password"
                    readOnly
                    value="2c44fbc4-cf78-4db3-8fe3-8bbe89a2e"
                    className="flex h-10 w-full rounded-md border border-[#446e6d25]   bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white"
                  />
                  <button className="bg-primary  text-white absolute right-2 top-1/2 -translate-y-1/2 h-7 w-7 flex items-center justify-center rounded-md hover:bg-accent hover:text-accent-foreground">
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          {/* Proxy List Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs">
            <div className="flex flex-row items-center justify-between p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white">
                Referral History
              </h3>
            </div>
            <div className="p-4 sm:p-6 pt-0">
              <div
                className="bg-muted text-gray-700 dark:text-white min-h-[295px] max-h-[360px] overflow-y-auto custom-scrollbar relative"
                style={{ borderRadius: "0px" }}
              >
                {loading ? (
                  <table className="w-full text-sm md:text-base">
                    <thead className="sticky bg-[#F3F4F6] dark:bg-bgp top-0 dark:text-primary font-normal text-sm">
                      <tr>
                        <th className="py-2">User</th>
                        <th className="py-2">Date</th>
                        <th className="py-2">Bonus</th>
                      </tr>
                    </thead>
                    <tbody className="text-center text-xs md:text-sm">
                      {[...Array(5)].map((_, index) => (
                        <tr
                          key={index}
                          className="border-b border-[#446e6d25] dark:border-dark-300"
                        >
                          <td className="pt-3 pb-2">
                            <div className="h-5 bg-gray-200 dark:bg-gray-700  mx-auto w-[5vw] rounded animate-pulse"></div>
                          </td>
                          <td className="pt-3 pb-2">
                            <div className="h-5 bg-gray-200 dark:bg-gray-700  mx-auto  w-16 rounded animate-pulse"></div>
                          </td>
                          <td className="pt-3 pb-2">
                            <div className="h-5 bg-gray-200 dark:bg-gray-700  mx-auto  w-10 rounded animate-pulse"></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : Array.from({ length: 20 }).length > 0 ? (
                  <table className="w-full text-sm md:text-base">
                    <thead className="sticky top-0 dark:text-primary font-normal text-sm">
                      <tr>
                        <th className="py-2 bg-[#F3F4F6] dark:bg-bgp rounded-l-md overflow-hidden">
                          User
                        </th>
                        <th className="py-2 bg-[#F3F4F6] dark:bg-bgp ">Date</th>
                        <th className="py-2 bg-[#F3F4F6] dark:bg-bgp rounded-r-md overflow-hidden ">
                          Bonus
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center text-xs md:text-sm">
                      {Array.from({ length: 20 }).map((_, i) => (
                        <tr
                          key={i}
                          className="border-b  border-[#446e6d25] dark:border-dark-300"
                        >
                          <td className="max-w-[5vw] overflow-hidden truncate pt-3 pb-1">
                            ajh5qD6GqHpU@gmail.com
                          </td>
                          <td className="pt-2 pb-1">2021-09-30</td>
                          <td className="pt-2 pb-1">10%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="h-[295px] flex items-center justify-center dark:text-gray-400">
                    No referral history found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
