import React from "react";
import { ChevronDown, Copy, Download } from "lucide-react";
import CustomInput from "../components/shared/ui/customInput";

export default function Page() {
  const dummyTableData = [
    {
      ip: "budget.digiproxy.cc:11000:ajh5qD6GqHpU-country-us-city-granville:hgdkfjhg327823t872asdf",
      date: "2021-09-30",
      plan: "1w",
      type: "Residential",
      action: <Copy style={{ width: "1em", height: "1em" }} />,
    },
    {
      ip: "budget.digiproxy.cc:11000:ajh5qD6GqHpU-country-us-city-granville:hgdkfjhg327823t872asdf",
      date: "2021-09-30",
      plan: "2w",
      type: "LTE",
      action: <Copy style={{ width: "1em", height: "1em" }} />,
    },
  ];
  return (
    <div className="px-6 mx-auto  dark:text-white">
      <div className="mb-3 lg:mb-6 flex flex-col gap-1 lg:gap-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-[26px] font-bold leading-[30px] text-dark dark:text-white">
          Generated Proxy
        </h2>
        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <a
                className="font-medium text-gray-600  dark:text-white"
                href="#"
              >
                Onboard /
              </a>
            </li>
            <li className="font-medium text-primary">Proxy-History</li>
          </ol>
        </nav>
      </div>
      <div className=" flex gap-6 items-start flex-col lg:flex-row">
        {/* Proxy Form */}
        <div className=" w-full">
          {/* Proxy List Card */}
          <div className="rounded-lg border border-[#446e6d25] bg-white text-card-foreground shadow dark:bg-bgs">
            <div className="flex flex-row items-center justify-between  p-4 sm:p-6">
              <h3 className="text-lg lg:text-xl font-semibold leading-none tracking-tight text-primary dark:text-white ">
                Proxy History
              </h3>
            </div>
            <div className=" p-4 sm:p-6 pt-0">
              <div className="bg-muted p-4 rounded-lg bg-[#F3F4F6] dark:bg-bgp text-gray-700 dark:text-white min-h-[295px]">
                <table className="w-full text-sm md:text-base">
                  <thead className="sticky top-0 bg-[#E6E5FD] dark:bg-[#302F47] pt-2">
                    <tr>
                      <th>Ip</th>
                      <th>Date</th>
                      <th>Plan</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center text-xs md:text-sm ">
                    {dummyTableData.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b border-[#446e6d25] dark:border-dark-300"
                      >
                        <td className="max-w-[5vw] overflow-hidden truncate pt-3">
                          <span className="">{item.ip}</span>
                        </td>
                        <td className="pt-2">{item.date}</td>
                        <td className="pt-2">{item.plan}</td>
                        <td className="pt-2">{item?.type}</td>
                        <td className="flex justify-center pt-3 mb-1">
                          <span className="p-2 rounded-lg bg-primary text-white">
                            {item.action}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
