import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../context/context";

export default function Footer() {
  const { user, loading } = useTheme();
  return (
    <section id="footer" className="relative text-black dark:text-white dark:bg-[#161616] bg-white ">
      {/* Gradient background */}
      <div className="absolute inset-0 -z-10 flex items-center justify-center bg-gradient-to-t via-background to-transparent from-background "></div>

      <div className="max-w-[1920px] py-5 sm:py-6 md:py-7 lg:py-8 mx-5 sm:mx-6 md:mx-7 lg:mx-auto pb-0">
        <div className="container mx-auto">
          {/* Call to Action Section */}
          <section className="relative bg-transparent ">
            <div className="max-w-[1920px] py-5 sm:py-6 md:py-7 lg:py-8 mx-5 sm:mx-6 md:mx-7 lg:mx-8 max-w-layout m-auto">
              <div className="container mx-auto">
                <div className="relative flex flex-wrap items-center justify-center w-full gap-5 mx-auto lg:flex-nowrap">
                  <div className="grid gap-5">
                    <div className="relative z-0 flex-grow text-center">
                      <h2 className="font-semibold text-xl md:text-4xl text-primary">
                        Start your Free Trial Now!
                      </h2>
                    </div>
                    <div className="relative z-0 flex-grow text-center lg:text-left">
                      <p className="text-lg mt-2 text-gray text-opacity-80">
                        Click below to begin a free trial and transform your
                        online operations.
                      </p>
                    </div>
                    <div className="gap-4 flex flex-col md:flex-row items-center justify-center">
                      {!user && !loading && <div className="relative flex-shrink-0 w-full text-center md:w-auto text-white">
                        <Link
                          className="group gap-2 py-2 rounded-full inline-flex justify-center sm:text-lg items-center w-full mx-auto font-semibold text-center px-6 hover:outline dark:text-bgp outline-[1px] bg-primary text-background"
                          to="/register"
                        >
                          Start Free Trial
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="group-hover:translate-x-2 transition-all"
                          >
                            <path
                              d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </Link>
                      </div>}
                      <div className="relative flex-shrink-0 w-full text-center md:w-auto">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block rounded-full hover:bg-primary-foreground hover:text-primary-light bg-primary-light py-2 mx-auto w-full font-semibold text-center text-primary bg-bordersm:text-lg px-6 outline outline-[1px]"
                          href="https://t.me/Nazmul1671"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Footer Links Grid */}
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-1 sm:gap-6">
              {/* Company Logo and Social Media */}
              <div className="flex flex-col gap-5 my-4">
                <div className="flex items-center justify-start gap-2 hover:opacity-80">
                  <Link to="/">
                    <div className="rt-Box lg:pr-8 flex items-center justify-center">
                      <span class="font-semibold text-3xl">
                        <span class="font-india text-primary">Q</span>socks
                      </span>
                    </div>
                  </Link>
                </div>
                <span className="text-sm font-medium text-gray-400 mb-2">
                  GET IN TOUCH
                </span>
                <div>
                  <div className="flex gap-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-light"
                      href="mailto:support@qsocks.com"
                    >
                      <div>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M437.332 80H74.668C51.199 80 32 99.198 32 122.667v266.666C32 412.802 51.199 432 74.668 432h362.664C460.801 432 480 412.802 480 389.333V122.667C480 99.198 460.801 80 437.332 80zM432 170.667L256 288 80 170.667V128l176 117.333L432 128v42.667z"></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-light"
                      href="https://t.me/qsocks"
                    >
                      <div>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-light"
                      href="https://x.com/qsocks"
                    >
                      <div>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 16 16"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-light"
                      href="https://www.youtube.com/@Qsocks"
                    >
                      <div>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 576 512"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:text-primary-light"
                      href="https://www.tiktok.com/@qsocks"
                    >
                      <div>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              {/* Company Links */}
              <div className="flex flex-col gap-5 my-4">
                <span className="text-sm font-medium text-gray-400 mb-2">
                  COMPANY
                </span>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/privacy"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/terms-of-service"
                  >
                    Terms of Service
                  </Link>
                </div>
              </div>

              {/* Products Links */}
              <div className="flex flex-col gap-5 my-4">
                <span className="text-sm font-medium text-gray-400 mb-2">
                  PRODUCTS
                </span>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/residential-proxies"
                  >
                    Residential Proxies
                  </Link>
                </div>
              </div>

              {/* Solutions Links */}
              <div className="flex flex-col gap-5 my-4">
                <span className="text-sm font-medium text-gray-400 mb-2">
                  SOLUTIONS
                </span>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/use-case/e-commerce"
                  >
                    E-Commerce
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/use-case/data-scraping"
                  >
                    Data Scraping
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/use-case/ad-verification"
                  >
                    Ad Verification
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/use-case/online-survey"
                  >
                    Online Survey
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/use-case"
                  >
                    See all solutions
                  </Link>
                </div>
              </div>

              {/* Resources Links */}
              <div className="flex flex-col gap-5 my-4">
                <span className="text-sm font-medium text-gray-400 mb-2">
                  RESOURCES
                </span>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/faqs"
                  >
                    FAQ
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/location"
                  >
                    All Locations
                  </Link>
                </div>
                <div>
                  <Link
                    className="dark:text-white text-black text-base font-semibold hover:text-primary"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </div>
              </div>
            </div>

            {/* Copyright Section */}
            <div className="flex justify-start">
              <div className="flex items-center justify-center mt-6 align-middle">
                <div>
                  <div className="rt-Box flex items-center justify-center lg:pr-8">
                    <span class="font-semibold ">
                      <span class="font-india text-primary">Q</span>socks
                    </span>
                  </div>
                </div>
                <span className="text-center mx-2 text-xs mt-1">
                  © {new Date().getFullYear()} qsocks.com
                </span>
                <span className="text-center text-xs  mt-1">
                  All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
