import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import toast from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyCLgYqIAD4CAjKtuUpO6q2njQJtuXGxh0k",
  authDomain: "qsocks-b62d6.firebaseapp.com",
  projectId: "qsocks-b62d6",
  storageBucket: "qsocks-b62d6.firebasestorage.app",
  messagingSenderId: "1030334753241",
  appId: "1:1030334753241:web:3a284c4f55608c61d1d1c8",
  measurementId: "G-DBJ36SJ6BW",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, provider);
export const logout = () => signOut(auth);

export default app;
